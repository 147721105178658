.sellingpoints {

    position: relative;
    background-color: $sellingpoints-bg-color;

    .triangle-1 {
        @include triangle-south-west($sellingpoints-trianble-1-bg-color, 100px);
        position: absolute;
        z-index: 1;
        bottom: -66px;
        left: -22px;
    }

    .triangle-2 {
        @include triangle-north-east($sellingpoints-trianble-2-bg-color, 70px);
        position: absolute;
        z-index: 1;
        top: -46px;
        right: 108px;
    }

}



.carousel.carousel-sellingpoints {

    .carousel-fixed-item {

        position: absolute !important;
        z-index: 99;
        bottom: 0;

        .nav {
            position: absolute !important;
            z-index: 1;
            right: 0;
            bottom: 0;
            left: auto;
            width: 68.4%;
            pointer-events: none;
        }

        .icon-angle-left,
        .icon-angle-right {

            position: absolute;
            z-index: 10;
            cursor: pointer;
            pointer-events: all;

            .fill {
                fill: $sellingpoints-carousel-arrow-color;
            }

        }

        .icon-angle-left {
            left: 40px;
        }

        .icon-angle-right {
            right: 40px;
        }

    }

    .carousel-item .text {
        padding: 10px 20px 0;

        h2 {
            margin-bottom: 20px;
        }

        p {
            font-size: 1.2rem;
        }

    }


    .indicators {

        z-index: 1;
        right: 0;
        bottom: 10px;
        left: auto;
        width: 38.4%;
        margin: 0 15%;


        .indicator-item {
            width: 14px;
            height: 14px;
            margin: 24px 8px;
        }

    }

}
