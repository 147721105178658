.page-section.panorama {

    background-color: $panorama-bg-color;
    background-position: 50% 50%;
    background-size: cover;

    .container {

        position: relative;
        height: 100%;

        .row {
            margin-bottom: 0;
        }

    }

}

.page-section.panorama-homepage {

    .caption {

        z-index: 2;
        margin: 8rem 0 6rem;

        .caption-image-james {
            margin-right: 5px;
            margin-bottom: -8px;

        }

        .caption-description {
            font-size: 1.8rem;
            margin:10px auto;
        }
    }

}

.page-section.panorama-intro {

    padding-bottom: 0;

    .caption {

        margin-top: 350px;
        padding: .5rem 2rem;
        overflow: hidden;
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        background: $panorama-intro-bg-color;
        text-align: center;

        .caption-header {
            margin-bottom: 0;
            background-color: $panorama-caption-header-bg-color;
            color: $panorama-caption-header-text-color;
            font-size: 2rem;
        }

        .caption-description {
            margin-top: .5rem;
            background-color: $panorama-caption-description-bg-color;
            color: $panorama-caption-description-text-color;
            font-size: 1.3rem;
            font-weight: bold;
            margin:10px auto;
        }

        .caption-jumpto {
            display: block;
            width: 45px;
            height: 45px;
            margin: 0 auto 20px;
        }

        .caption-jumpto-arrow {
            width: 100%;
            height: 100%;
            fill: currentColor;
            color: $panorama-caption-jumpto-arrow-color;
        }

        @media #{$extra-large-and-up} {
            margin-top: 250px;
        }

        @media #{$large-and-up} {
            margin-top: 200px;
        }

        @media #{$medium-and-down} {
            margin-top: 150px;
        }

        @media #{$small-and-down} {
            margin-top: 60px;

            .caption-header {
                font-size: 1.5rem;
            }

            .caption-description {
                font-size: 1rem;
            }

            .caption-jumpto-arrow {
                width: 30px;
                height: 30px;
            }

        }

    }

}
