.my_repairs{
    background:$repair-block-background-color;
    border:1px solid #9e9f9c;
    padding:20px;
    overflow:hidden;

    table{
        margin-bottom:30px;

        thead{
            .table__heading{
                color:$repair-block-heading-color;
            }
            @media #{$medium-and-down} {
                border:0;
            }
        }

        tbody{
            tr{
                border-bottom:1px solid #d0d0d0;
                cursor:pointer;
                position:relative;

                @media #{$medium-and-down} {
                    padding:0 !important;
                    margin-bottom: 2rem;
                }

                &:hover {
                    background-color: $repair-block-row-hover;
                }

                td{
                    color:$repair-block-text-color;
                    vertical-align: top;

                    @media #{$medium-and-down} {
                        &:before{
                            width:100%;
                            display:block !important;
                            color: $repair-block-heading-color;
                            font-weight: 600 !important;
                            margin-bottom:10px;
                        }
                        border-bottom:1px solid #d0d0d0;
                        padding-bottom:10px !important;
                        padding-top:10px !important;
                    }


                }

                @media #{$medium-and-down} {

                    &:after {
                        content:' \02C3';
                        width:50px;
                        height:50px;
                        font-size:30px;
                        color:#000;
                        right:0;
                        bottom:0;
                        position:absolute;
                        z-index:3;
                        display:block;
                        text-align:right;
                    }

                }
            }
        }
    }
}

.my_repairs, .messageBody {
    [class^="status-"]{
        padding:5px;
        color:$color-primary-white;
        border-radius:10px;
        width:160px;
        display:block;
        text-align:center;
    }
    .status-0{
        background:$repair-block-status-0;
    }
    .status-1{
        background:$repair-block-status-1;
    }
    .status-2{
        background:$repair-block-status-2;
    }
    .status-3{
        background:$repair-block-status-3;
    }
    .status-4{
        background:$repair-block-status-4;
    }
}
.repair_request_detail {
}
.repair_request_detail {
    #repairStatusForm {
        margin-bottom: 50px;
    }
}

#repair_request_status {
    background:$repair-block-background-color;
    margin-bottom: 30px;
    border: 1px solid $color-primary-gray-1;
    padding: 20px;
    margin-top:20px;
    .select-wrapper {
        padding-top: 15px;
    }
    label {
        font-size: 1rem;
        font-weight: bold;
    }
}

#changeRepairStatusModal {
    top: 35%!important;
}
