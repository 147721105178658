// -------------------------------------
//   Services icongrid section styling
// -------------------------------------

.page-section.servicegrid {

    padding: 5rem 0;
    background-color: $servicegrid-bg-color;

    .container {
        position: relative;
    }

    .triangle-1 {
        @include triangle-north-east($servicegrid-trianble-1-bg-color, 80px);
        position: absolute;
        z-index: 1;
        top: -127px;
        right: -13px;
    }

    .section-header {

        // TODO: Fix font size of section header
        .section-title {
            margin: 0 0 3rem;
            color: $servicegrid-header-text-color;
        }

    }


    .services {

        .col{
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .service {
            display: block;
            color: $servicegrid-service-text-color;
            font-weight: bold;
            text-align: center;

            .icon-wrapper{
                height:64px;
                align-items: center;
                justify-content: center;
                display: flex;

                img{
                    max-height:100%;
                }

                [class^="icon-"]{
                    font-size: 64px;
                    line-height: 1em;
                }
            }
            .service-icon,
            .service-label {
                display: block;
            }

            .service-label {
                padding: .4rem 0;
                font-size: 1.333rem;
                font-weight: 400;
            }

            .service-icon {
                display: block;
                width: 100%;
                max-width: 65px;
                max-height: 65px;
                margin: 10px auto 0;
                fill: currentColor;
                font-size: 8px;
            }

            .service-coming-soon {
                background-color: $servicegrid-comming-soon-color;
                color: $color-primary-dark;
                padding: .1rem .8rem;
                font-size: 1rem;
                font-weight: 500;
            }

            .icon-tools { max-width: 60px; }
            .icon-exchange { max-width: 60px; }
            .icon-buggy { max-width: 70px; }
            .icon-flowers { max-width: 60px; }

            &:hover{
                opacity:0.6;
            }

        }

        .service-disabled {
            pointer-events: none;
            color: $servicegrid-comming-soon-color;

            img{
                opacity:0.4;
            }
        }

    }

    .col {
        position: relative;
        z-index: 1;
        height: 165px;
        border-right: solid 1px $servicegrid-col-border-color;
        border-bottom: solid 1px $servicegrid-col-border-color;
    }

    //every last column in a row of 4 no crosshair
    .col:nth-child(2n) {
        border-right: 0;
    }

    //bottom row no crosshair  and border
    .col:nth-last-child(-n+2) {
        border-bottom: 0;
        .crosshair{
            display:none;
        }
    }

    //every first item in a row nog crosshair
    .col:nth-child(2n+1) {
        .crosshair{
            display:none;
        }
    }

    @media #{$medium-and-up} {

        .col:nth-child(2n) {
            border-right: solid 1px $servicegrid-col-border-color;
        }

        col:nth-last-child(-n+2) {
            border-bottom: solid 1px $servicegrid-col-border-color;
            .crosshair{
                display:block;
            }
        }

        .col:nth-child(2n+1) {
            .crosshair{
                display:block;
            }
        }

        //every last column in a row of 3 no crosshair
        .col:nth-child(3n) {
            border-right: 0;
        }

        //bottom row no crosshair  and border
        .col:nth-last-child(-n+3) {
            border-bottom: 0;
            .crosshair{
                display:none;
            }
        }

        //every first item in a row nog crosshair
        .col:nth-child(3n+1) {
            .crosshair{
                display:none;
            }
        }
    }

    @media #{$large-and-up} {

        .col:nth-child(3n) {
            border-right: solid 1px $servicegrid-col-border-color;
        }

        .col:nth-last-child(-n+3) {
            border-bottom: solid 1px $servicegrid-col-border-color;
            .crosshair{
                display:block;
            }
        }

        .col:nth-child(3n+1) {
            .crosshair{
                display:block;
            }
        }

        //every last column in a row of 4 no crosshair
        .col:nth-child(4n) {
            border-right: 0;
        }

        //bottom row no crosshair  and border
        .col:nth-last-child(-n+4) {
            border-bottom: 0;
            .crosshair{
                display:none;
            }
        }

        //every first item in a row nog crosshair
        .col:nth-child(4n+1) {
            .crosshair{
                display:none;
            }
        }
    }


    .crosshair {
        position: absolute;
        z-index: 20;
        bottom: -27px;
        left: -25px;
        width: 50px;
        height: 50px;
        opacity: 1;
        background: $servicegrid-crosshair-bg-color;
        color: $servicegrid-crosshair-color;
    }

    .crosshair::before,
    .crosshair::after {
        content: ' ';
        position: absolute;
        top: 12px;
        right: 25px;
        width: 1px;
        height: 21px;
        background-color: $servicegrid-crosshair-cross-color;
    }

    .crosshair::before {
        transform: rotate(0deg);
    }

    .crosshair::after {
        transform: rotate(90deg);
    }

    .crosshair-nocross {

        &::before,
        &::after {
            display: none;
        }

    }

    .crosshair-right {
        right: -35px;
        bottom: -27px;
        left: auto;
    }

    .crosshair-left {
        bottom: -27px;
        left: -35px;
    }

}




.page-section.servicegrid-alternative {

    background-color: $servicegrid-alt-bg-color;
    color: $servicegrid-alt-service-text-color;

    .triangle-1 {
        @include triangle-north-east($servicegrid-alt-trianble-1-bg-color, 80px);
    }

    .col {
        border-right: solid 1px $servicegrid-alt-col-border-color;
        border-bottom: solid 1px $servicegrid-alt-col-border-color;
    }

    .crosshair {
        border: solid $servicegrid-alt-crosshair-cross-color 0;
        background: $servicegrid-alt-crosshair-bg-color;
    }


    .services {

        .service {

            color: $servicegrid-alt-service-text-color;

            .service-icon {
                fill: currentColor;
            }

        }

    }

}


@media all and (max-width: 500px){
    #section-services.page-section.servicegrid{
        padding: 0;

        .container .services {

            div.col {
                margin-left: auto;
                left: auto;
                right: auto;
                border-right: 0;
                border-bottom: 0;

                span.crosshair {
                    display: none;
                }
            }
        }
    }

    #section-services-highlights{
        .card {
            margin-bottom: 0;

            .card-link {
                padding-bottom: 8px;
            }
        }
    }
}
body.building_index {
    #section-services {
        &.servicegrid {
            background-color: $servicegrid-bg-color-inversed;
            .section-header {
                .section-title {
                   color: $servicegrid-header-text-color-inversed;
                }
            }
            .services {
                .service {
                    color: $servicegrid-service-text-color-inversed;
                }
            }
            .crosshair {
                background: $servicegrid-crosshair-bg-color-inversed;
                color: $servicegrid-crosshair-color-inversed;
                &::before,
                &::after {
                    background-color: $servicegrid-crosshair-cross-color-inversed;
                }
            }
            .triangle-1 {
                @include triangle-north-east($servicegrid-trianble-1-bg-color-inversed, 80px);
            }
            .col {
                border-right-color: $servicegrid-col-border-color-inversed;
                border-bottom-color: $servicegrid-col-border-color-inversed;
            }
        }
    }
}
