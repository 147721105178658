.service-products{

  h2{
    &.center-align{
      font-size:2.813rem;
      @media #{$medium-and-up} {
        text-align:left;
        font-size:3.56rem;
      }
    }
  }

  address{
    font-style:normal;
  }

  [type=radio]:checked+label:after{
      background-color: $shop-step-input-field;
      border: 2px solid $shop-step-input-field;
  }

  [type=radio]:not(:checked)+label:after, [type=radio]:not(:checked)+label:before{
      border: 2px solid $shop-step-input-field;
  }

  input[type="radio"]:checked+label+.collapsible-body{
      display:block !important;
  }

  table.responsive .table__content:before{
      margin:0px;
      padding:0px;
      font-weight:bold;
      width:100%;
      display:block;      
  }

  table{    
    font-size:1.333rem;  
    width:100%;

    tr{
      @media screen and (max-width: 993px) {
        display:inline-block;
        width:100%;
      }

      th{
        padding: 5px 5px;

      }
      td{
        padding: 5px 5px;


        &.number{
          vertical-align:top;
        }

        .product-sku{
          margin-top:0;
        }

        &.number{
          vertical-align:top;
        }

        .product-sku{
          margin-top:0px;
        }

        .number{          
          b{
            font-weight:300;
          }
        }
      }
    }

    &.picker__table{
      tr{
        display:table-row;
        td{
          display:table-cell;
        }
      }
    }

    &.product_overview{

      thead{
        th{
          font-weight:500;
          font-size:1.64rem;
        }
        border:0;
        @media #{$medium-and-up} {
          border-top:1px solid #d0d0d0;
        }
      }

      tbody{
        font-weight:400;
        font-size:1.64rem;
        tr{
          border-bottom:1px solid #d0d0d0;
          @media #{$medium-and-up} {
            border-bottom:0px;
          }
        }
      }

      tfoot{   
        @media #{$medium-and-up} {
          border-top:1px solid #d0d0d0;
        }
        font-size:1.64rem;

        .total_price{
          th{
          font-weight:500;
          font-size:1.64rem;
          }
          border-top:1px solid #d0d0d0;
        }
      }
    }

  }

  .orderinfo{
    @media screen and (max-width: 993px) {
      margin-bottom:10px;
    }
  }

  ul{
    padding-left: 0px !important;

    li{
      list-style: none !important;
    }
  }
  .sonata-payment-confirmation{
    ul{
      padding-left:20px !important;
    } 
    li{
      list-style: square  !important;
    }
  }

  .collapsible{
    border: none;
    box-shadow: none;
    margin-bottom: 100px;
    .collapsible-body{
      border:none;
    }
    

    .collapsible-header.active i {
      transform: rotate(45deg);
    }

    .collapsible-header i {
      transform: rotate(-45deg);
      margin-left:-15px;
      margin-right: 1rem;
    }

    .collapsible-header{
      background: none;
      border:none;
      padding:0;
      position:relative;
      &.address-header{
        width:100%;
        display:inline-block;
        float:left;
        padding:0 0 0 40px;
        height:30px;
        line-height:20px;      
      }

      .price-per-product{
        position:absolute;
        right:0;
        display:none;
      }
    }

    label{
      font-size:1.333rem;
    }
    

    address{
      font-size:1rem;
    }

    .collapsible-body{
      padding:0;
      padding-left:2rem;
      i{
        display:inline;
        cursor: pointer;       
      }

      .btn{
        margin-bottom:20px;
      }

      input[type=text]{
        border: 1px solid $service-product-amount-border !important;       
        line-height: 2.4rem;
        font-size: 1.2rem;
        height:2.4rem;
        margin:0;
        border-left:none!important;
        border-right:none!important;
        width: 66%;
        max-width: 111px;
      }

      input[type=text]:disabled{
        color: rgba(0,0,0,1);
      }

      table{
        
        td{
          padding: 5px 5px 5px 0;
          &.price{
            min-width:100px;
            @media #{$medium-and-up} {
              text-align:right;
            }
            
          }
          display:table-cell;
          // font-size:1rem;
          @media screen and (min-width: 993px) {
            font-size:1.333rem;
          }

          &:first-child{
            display:block;

            @media #{$medium-and-up} {
              display:table-cell;
            }
          }

        }
      
      }
    }
  }



  .service-product-sidemenu{
    margin:0;
    display:none;
    @media #{$medium-and-up} {
      display:block;
    }
    a{
      color: $service-product-sidemenu-color;
      display:inline-block;
    }

    li.active{
      font-size:0px;

      .material-icons{
        transform: rotate(-45deg);
        display:inline-block;
        font-size:1.333rem;
        margin-left:-15px;
        margin-right:1rem;
        width:2rem;      
      }
    }

    li.disabled{
      margin-left:2rem;
      a {
        h5{       
          color: $service-product-disabled-menu-color;         
        }
      }
    }

  }

  .panel-title{
    font-weight:bold;
    font-size:1.333rem;
  }

  .panel-body{
    font-size:1rem;
    i,address{
      font-style:normal;
    }
  }
  input[value=MollieIDealPayment] + label{
    height:34px;
    line-height:40px;
    display:inline-block;
    background:url('/images/IDEAL_Logo.png');
    background-size:contain;
    background-repeat:no-repeat;
    background-position: 30px 0px;
    border-radius:0px !important;
    padding:0 10px 0 40px;
    margin-right:10px;
    text-indent:-9999px;
    margin-bottom:20px;
    width: 80px;
    [type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
      margin-top:12px;
    }

    &:after, &:before{
      top: 50%;
      margin-top: -8px;
    }
  }

  input[value=MollieCreditCardPayment] + label{
    height:34px;
    line-height:40px;
    display:inline-block;
    background:url('/images/CREDITCARD_Logo.png');
    background-size:contain;
    background-repeat:no-repeat;
    background-position: 30px 0px;
    border-radius:0px !important;
    padding:0 10px 0 90px;
    margin-right:10px;
    text-indent:-9999px; 
    width:200px;
    margin-bottom:20px;
    [type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
      margin-top:12px;
    }

    &:after, &:before{
      top: 50%;
      margin-top: -8px;
    }
  }

  input[value=InvoicePayment] + label{
    height:34px;
    line-height:40px;
    display:inline-block;
    background:url('/images/INVOICE PAYMENT-conv.png');
    background-size:contain;
    background-repeat:no-repeat;
    background-position: 30px 0px;
    border-radius:0px !important;
    padding:0 10px 0 90px;
    margin-right:10px;
    width:230px;
    margin-bottom:20px;
    [type=radio]:checked+label:after,[type=radio]:not(:checked)+label:after{
      margin-top:12px;
    }

    &:after, &:before{
      top: 50%;
      margin-top: -8px;
    }
  }
  
  .well{ 
    margin-top:25px;
    .cart-update-button{
      @media screen and (max-width: 445px) {
        float:right;
        margin-top:10px;
        margin-bottom:10px;
        }

        @media screen and (min-width: 601px) {
          float:right;
          margin-top:10px;
          margin-bottom:10px;
          }

          @media screen and (min-width: 710px) {
            float:left;
        margin-top:0;
            }
        float:left;
        margin-top:0;
    }

    .cart-previous-button{
      @media screen and (max-width: 352px) {
        float:right;
        margin-top:10px;
        margin-bottom:10px;
        }
        float:left;
        margin-top:0;
    }

    .cart-previous-button{
      @media screen and (max-width: 352px) {
        float:right;
        margin-top:10px;
        margin-bottom:10px;
        }
        float:left;
        margin-top:0;
    }
  }

  .amount-box{
    position:relative;

    input[type=text]{
      border: 1px solid $service-product-amount-border !important;       
      line-height: 2.4rem;
      font-size: 1.2rem;
      height:2.4rem;
      margin:0;
      border-left:none!important;
      border-right:none!important;
      width: 66%;
      max-width: 111px;
    }
  
    .loader{ 
      display: none;
      position: absolute;
      text-align: center;
      left: 43%;
      margin-left: -24px;
      top: 0px;
      @media screen and (max-width: 600px) {
        left:50%;
        margin-left: -20px;
      }
    }
    
    i{
      border: 1px solid $service-product-amount-border !important;
      width: 37px;     
      min-width: 37px;     
      text-align:center;
      cursor:pointer;
      opacity:1;
      line-height: 2.4rem;
      font-size: 1.2rem;
    }

    input[type=text]{
      border-top: 1px solid $service-product-amount-border !important;
      border-bottom: 1px solid $service-product-amount-border !important; 
      line-height: 2.4rem;
      font-size: 1.2rem;
      height:2.4rem;
      margin:0;
    }
    &.loading{
      input, i{
        opacity:0.3; 
        cursor:default
      }
    }
    &.disabled{
      [data-action=add]{
        opacity: 0.1;
        cursor: default; 
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
          -moz-user-select: none; 
            -ms-user-select: none; 
                user-select: none; 
      }
      label {
        opacity: 0.1;
        cursor: default;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }

    label{
      border-top: 1px solid $service-product-amount-border !important;
      border-bottom: 1px solid $service-product-amount-border !important;
      border-right: 1px solid $service-product-amount-border !important;
      margin: 0;
      height: 2.47rem;
      line-height: 2.4rem;
      font-size: 1.2rem;
      color: #002e47;
      background-color: transparent;
      width: 185px;
      max-width: 100%;
    }

    .checkcontainer {
      display: block;
      position: relative;
      padding-left: 43px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .checkcontainer input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 36px;
      width: 36px;
      border-right: 1px solid $service-product-amount-border !important;
      border-left: 1px solid $service-product-amount-border !important;
      border-bottom: 1px solid $service-product-amount-border !important;
    }

    .checkcontainer input:checked ~ .checkmark {
      background-color: $service-product-checkbox-background;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    .checkcontainer input:checked ~ .checkmark:after {
      display: block;
    }

    .checkcontainer .checkmark:after {
      left: 9px;
      top: 5px;
      width: 15px;
      height: 20px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  li:first-child .collapsible-header .price-per-product{
    display:none;
    @media screen and (max-width: 381px) {
      margin-top:10px;
    }
    @media #{$large-and-up} {
      display:block;
    }
  }

  form{
    .materialize-choice_widget_expanded {
        padding-top: 2.5rem;
    }
    .input-field{
      margin-top:2rem;
    }
    label{
      font-weight: 400;
      font-size: 1.64rem;
      line-height: 2rem;
    }

    textarea.materialize-textarea{
        padding: 2rem .7rem 1.7rem .7rem;  
        text-indent: 0;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .input-field label:not(.label-icon).active {
        -webkit-transform: translateY(-22px) scale(.8);
        transform: translateY(-22px) scale(.8);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }
  } 

  form .input-field .customLabel, form .customLabel{
      position:relative;
      width:100px;
      display:block;
      float:left;
      margin:0 0 20px 0;
      padding:0;
      font-size:1.64rem;
      transform:none;
 
      @media #{$large-and-up} {
        width:200px;
      }
  }

  form .input-field .customInput, form .customInput{
    width:calc(100% - 100px);
    margin:0 0 20px 0;
    padding:0;
    float:left;

    @media #{$large-and-up} {
      width:calc(100% - 200px);
    }
  }

  .service_notes, #service_booking_notes {
    height: 7rem;
    border: 1px solid #000;
    padding: .8rem .8rem 1.6rem;
    box-sizing: border-box;
  } 

  .collapsible .collapsible-body{
    .btn{
      margin: 0;
      height: 2.47rem;
      line-height: 2.4rem;
      font-size: 1.2rem;
      border:1px solid $btn-product-border-color;
      color:$btn-product-color;
      background-color:transparent; 
      width: 185px;
      max-width: 100%;
    }

    .product-item{
        p{
          margin: 0;
          font-size: 1rem;
          font-style: italic;
          }
    }
    .products-grid{
      .row{
        display: flex;
        flex-wrap: wrap;

        .col{
          margin-bottom: 1rem;
          margin-left: 0; 
        }
      }

      .loader{       
        left: 38%;  
      }

      .product-item, .variation-item{
        padding:20px;
        background: $service-product-grid-item-bg-color;
        height:100%;
        display: flex;
        flex-direction: column;
      }

      .variation-item{
        // padding:0;
        // background: none;
        .loader{
          left:50%;
        }
      }

      .product-image{
          display:block;
          padding-top:80%;
          background:$service-product-image-bg-color;
          background-size: cover;
          background-position: center;
      }

      h4{
        font-size: 1.875rem;
        font-weight: 500;
        margin: 1.14rem 0 .912rem; 
      }
      .product-price{
        font-size: 1.875rem;
        margin-bottom: auto;
      }
      
      .btn{
        // background:$service-product-btn-color;
        // font-size: 1.0rem;
        // display:block;
        // color:$service-product-btn-txt-color;
        // line-height: 2rem;
        margin-top:1.14rem;
        // margin-bottom:0; 
        // height: 2.06rem;
        // text-transform: uppercase;

        &:hover{
          background: $service-product-btn-hover-color;
          border: 1px solid $service-product-btn-color;
          color: $service-product-btn-color;
        }
      }
      .amount-box{
        margin-top:1.14rem;        
      }
      
    }
    
  }

  .product-modal{
    margin-top: 50px;
  }    

  .product_overview{
    .table__content, .table__heading{
      padding:5px 0;
    }

    &+ .quotation-form{
      border-top: 1px solid #d0d0d0;
    }
  }

  .booking_date_pickere_widget > label {
      position: unset;
  }
}