.page-section .faq_collapsible{
    border:0;
    box-shadow:none;
    color:$collapsible-color;
    padding-left: 0;
    li{
        list-style: none;

        ul {
            li{
                list-style: square;
            }
        }

        ol{
            li{
                list-style: decimal;
            }
        }
    }
    .collapsible-header{
        border:0;
        font-size:1.5rem;
        background-color:$collapsible-bg-color;
        @media screen and (min-width:1200px){
            font-size:2rem;
        }
        i{
            font-size:2rem;
            color:$collapsible-icon-color;
            line-height:2rem;
            transform: rotate(45deg);
            display:block;
            height:100%;
            margin-right:2rem;
        }

        &.active{
            i{
                transform: rotate(225deg);
                transform-origin: 60% 73%;
            }
        }

        .title{
            color:$collapsible-title-color;
        }

    }

    .collapsible-body{
        border:0;
        padding: 0rem 2rem 0rem 5rem;
        font-size:1.333rem;
        color:$collapsible-color;
        overflow: hidden;
        @media screen and (max-width:500px){
            table{
                max-width:100% !important;
                width:100% !important;
            }
        }
    }
}
