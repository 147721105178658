#toast-container{
     z-index: 99999999;   
    .toast{   
        background: $toast-bg-color;
        font-size: 1.1rem;
        font-weight: 400;
        border-radius:0;
        .toast-action{
            color:$toast-color;
        }

        a{
            color:$toast-color;
            text-decoration: underline;
        }
    }
      

}

#flash-toasts{ 
    display:none; 
}
