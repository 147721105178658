.profile-menu{
    margin-top:0;
    display:flex;
    li{
        display:flex;
        flex-grow: 1;
        margin-right:-2px;
       
        .btn.btn-dark.btn-border, .btn.btn-dark{
            width:100%;
            border-top:0;          
            font-size: 1.1rem;
        }
    }

    .toggle{
        display: none;
    }

    @media screen and (max-width:1100px){
        background: $profile-menu-mobile-bg-color;
        position: relative;
        display:block;
     

        li{
            display: block;           
            flex-grow: unset;
            width:100%;
            .btn.btn-dark.btn-border{
                border:none;
                color:$profile-menu-mobile-color;
                background: $profile-menu-mobile-bg-color;
            }

            &.active{
               // width: calc(100% - 42px);
            }
            &:not(.active){
                display:none;
            }
        }

        &.open{
           li{
               display:block;
           } 

           .toggle{
               i{
                transform: rotate(-90deg);
                margin-left:-1px;
               }
           }
        }

        .toggle{
            display: block;
            position: absolute;
            background: $profile-menu-toggle-bg-color;
            z-index: 2;
            right: 0;
            top: 0;
            width: 38px;
            text-align: center;
            height: 3.06rem;
            i{
                font-size:3.1rem;
                line-height: 3.1rem;
                color:$profile-menu-toggle-color;
                margin-left:-3px;
            }
        }
        
    }
}