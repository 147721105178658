// Import generic includes
@import '../includes/materialize';            // Include the main Materialize styles.
@import '../includes/variables';              // Include middle layer variables (mainly colors)
@import '../includes/mixins';                 // Include custom mixins
@import '../includes/utils';                  // Include utility classes like background colors
@import '../includes/svg-icons';              // Include SVG icon styles
@import '../includes/triangles';              // Include the triagle mixin system

// --------------------------------------------------------------------------
//
// INFO: To get an idea of all available color variable,
//       please resort to the scss/includes/_variables.scss file.
//
// --------------------------------------------------------------------------
// Toplevel Body variables:                scss/application.scss
// --------------------------------------------------------------------------

$body-font:                                $font-variable;
$body-font-color:                          $color-primary-dark;
$body-background-color:                    $color-primary-white;
$body-selection-color:                     $color-primary-dark;
$body-selection-background:                $color-primary-tint-3;

$header-font:                              $font-variable;
$link-color:                               $color-primary-dark;


// --------------------------------------------------------------------------
// Butons:                                 scss/components/_buttons.scss
// --------------------------------------------------------------------------

// .btn
$btn-default-bg-color:                     $color-primary-tint-6;
$btn-default-bg-color-focus:               $color-primary-tint-6;
$btn-default-bg-color-hover:               $color-primary-tint-6;
$btn-default-font-color:                   $color-primary-white;

// .btn.btn-light
$btn-light-color:                          $color-primary-white;
$btn-light-bg-color:                       $color-primary-tint-6;
$btn-light-bg-color-focus:                 $color-primary-tint-6;
$btn-light-bg-color-hover:                 $color-primary-tint-6;

// .btn.btn-light.btn-border
// $btn-light-btn-border-border-color:        $color-primary-tint-4;
// $btn-light-btn-border-bg-color:            $color-primary-white;
// $btn-light-btn-border-text-color:          $color-primary-dark;

// .btn.btn-light.btn-arrow
$btn-light-arrow-angle-color:              $color-primary-white;
$btn-light-arrow-seperator-color:          $color-primary-tint-3;

$btn-dark-arrow-angle-color:               $color-primary-white;
$btn-dark-arrow-seperator-color:           $color-primary-tint-3;
$btn-dark-arrow-color:                     $color-primary-white;

$btn-light-btn-border-border-color:        $color-primary-tint-6;
$btn-light-btn-border-bg-color:            transparent;
$btn-light-btn-border-text-color:          $color-primary-tint-6;

// .btn.btn-dark
$btn-dark-bg-color:                        $color-primary-tint-5;
$btn-dark-bg-color-focus:                  $color-primary-tint-5;
$btn-dark-bg-color-hover:                  $color-primary-tint-5;

// .btn.btn-dark-readmore
$btn-dark-read-more-color:                     $color-primary-dark;
$btn-dark-read-more-bg-color:                  $color-primary-white;
$btn-dark-read-more-bg-color-focus:            $color-primary-white;
$btn-dark-read-more-bg-color-hover:            $color-primary-white;

// .btn.btn-dark-read-more.btn-border
$btn-dark-read-more-btn-border-border-color:   $color-primary-dark;
$btn-dark-read-more-btn-border-bg-color:       transparent;
$btn-dark-read-more-btn-border-text-color:     $color-primary-dark;

// .btn.btn-light.btn-border
// $btn-dark-btn-border-border-color:         $color-primary-tint-4;
// $btn-dark-btn-border-bg-color:             $color-primary-transparent;
// $btn-dark-btn-border-text-color:           $color-primary-tint-4;
$btn-dark-btn-border-border-color:         $color-primary-tint-5;
$btn-dark-btn-border-bg-color:             transparent;
$btn-dark-btn-border-text-color:           $color-primary-tint-5;

// .btn.btn-dark
$btn-dark-green-color:                     $color-primary-tint-4;
$btn-dark-green-bg-color:                  $color-primary-accent;
$btn-dark-green-bg-color-focus:            $color-primary-accent;
$btn-dark-green-bg-color-hover:            $color-primary-accent;

// .btn.btn-dark
$btn-dark-read-more-color:                 $color-primary-tint-3;
$btn-dark-read-more-bg-color:              $color-primary-accent;
$btn-dark-read-more-bg-color-focus:        $color-primary-accent;
$btn-dark-read-more-bg-color-hover:        $color-primary-accent;

// .btn.btn-dark-green.btn-border
$btn-dark-green-btn-border-border-color:   $color-primary-accent;
$btn-dark-green-btn-border-bg-color:       $color-primary-white;
$btn-dark-green-btn-border-text-color:     $color-primary-accent;

$btn-read-more-arrow-angle-color:          $color-primary-dark;
$btn-read-more-arrow-seperator-color:      $color-primary-tint-3;

// .btn.btn-dark-green.btn-border
$btn-arrow-angle-color:                    $color-primary-white;
$btn-arrow-seperator-color:                $color-primary-white;

// .btn.btn-check
$btn-checkbox-seperator-color:             $color-primary-white;
$btn-checkbox-background-color:            $color-primary-white;
$btn-checkbox-check-color:                 $color-primary-tint-5;
$btn-checkbox-check-bg-color:              $color-primary-tint-2;
$btn-checkbox-check-border-color:          $color-primary-tint-3;

// .btn.btn-dark read more
$btn-dark-read-more-color:                     $color-primary-dark;
$btn-dark-read-more-bg-color:                  $color-primary-white;
$btn-dark-read-more-bg-color-focus:            $color-primary-white;
$btn-dark-read-more-bg-color-hover:            $color-primary-white;

// .btn.btn-dark-read-more.btn-border
$btn-dark-read-more-btn-border-border-color:   $color-primary-dark;
$btn-dark-read-more-btn-border-bg-color:       transparent;
$btn-dark-read-more-btn-border-text-color:     $color-primary-dark;

$btn-light-read-more-color:                 $color-primary-white;
$btn-light-read-more-bg-color:              $color-primary-dark;
$btn-light-read-more-bg-color-focus:        $color-primary-dark;
$btn-light-read-more-bg-color-hover:        $color-primary-dark;

// .btn.btn-dark-read-more.btn-border
$btn-arrow-angle-color:                    $color-primary-white;
$btn-arrow-seperator-color:                $color-primary-white;
$btn-light-arrow-angle-color:              $color-primary-white;
$btn-light-arrow-seperator-color:          $color-primary-tint-3;
$btn-read-more-arrow-angle-color:          $color-primary-white;
$btn-read-more-arrow-seperator-color:      $color-primary-tint-3;

$btn-read-more-arrow-angle-color:          $color-primary-dark;
$btn-read-more-arrow-seperator-color:      $color-primary-dark;


// .btn.btn-alternative-readmore
$btn-alternative-color:                     $color-primary-white;
$btn-alternative-bg-color:                  $color-primary-dark;
$btn-alternative-bg-color-focus:            $color-primary-dark;
$btn-alternative-bg-color-hover:            $color-primary-dark;


//

$btn-transparent-bg-color:                 $color-primary-transparent;
$btn-transparent-color:                    $color-primary-white;
$btn-transparent-border-color:             $color-primary-white;

$btn-product-color:                        $color-primary-dark;
$btn-product-border-color:                 $color-primary-dark;

// .btn-border | Buttons with border
$btn-border-width:                         1px;


// --------------------------------------------------------------------------
// Cards:                                  scss/components/_cards.scss
// --------------------------------------------------------------------------

$card-date-bg-color:                       $color-primary-tint-3;
$card-date-text-color:                     $color-primary-tint-5;
$card-date-day-bg-color:                   $color-primary-tint-4;
$card-date-day-text-color:                 $color-primary-white;
$card-img-border-color:                    $color-primary-tint-5;
$card-img-border-color-dark:               $color-primary-tint-5;
$card-img-border-color-light:              $color-primary-tint-5;
$card-bg-color:                            $color-primary-tint-1;
$card-text-color:                          $color-primary-dark;
$card-text-light-color:                    $color-primary-tint-3;
$card-overlay-icon-color:                  $color-primary-white;
$card-overlay-icon-shadow-color:           $color-primary-black;

// --------------------------------------------------------------------------
// Events:                                 scss/components/_events.scss
// --------------------------------------------------------------------------




// --------------------------------------------------------------------------
// Site Footer:                            scss/components/_footer.scss
// --------------------------------------------------------------------------

$site-footer-bg-color:                     $color-primary-tint-3;
$site-footer-color:                         $color-primary-white;
$site-footer-link-color:                   $color-primary-dark;



// --------------------------------------------------------------------------
// Forms                                   scss/components/_forms.scss
// --------------------------------------------------------------------------

$form-border-color:                        $color-primary-dark;
$form-input-text-color:                    $color-primary-dark;
$form-fields-border-color:                 $color-primary-dark;
$form-fields-valid-border-color:           $color-primary-dark;
$form-fields-invalid-border-color:         $color-primary-error;
$form-fields-focus-border-color:           $color-primary-tint-4;
$form-fields-focus-label-color:            $color-primary-tint-4;
$form-field-checkbox-border-color:         $color-primary-tint-4;
$form-infobox-bg-color:                    $color-primary-dark;
$form-infobox-text-color:                  $color-primary-white;
$form-infobox-icon-color:                  $color-primary-white;
$form-information-bg-color:                $color-primary-dark;
$form-information-text-color:              $color-primary-white;
$form-information-booking-bg-color:        $color-primary-accent;
$form-information-booking-text-color:      $color-primary-white;

$blanco-profile-image-url:                 "/images/james-blanco-profile-image.png";


// --------------------------------------------------------------------------
// Infobox Forms                            scss/components/_infobox-form.scss
// --------------------------------------------------------------------------

$infobox-background-color:                  $color-primary-tint-2;
$infobox-form-background-color:             $color-primary-white;
$infobox-form-border-color:                 $color-primary-dark;


// --------------------------------------------------------------------------
// Site Header:                            scss/components/_navbar.scss
// --------------------------------------------------------------------------

$site-header-bg-color:                     $color-primary-tint-2;
$site-header-link-color:                   $color-primary-dark;
$site-header-text-color:                   $color-primary-dark;
$site-header-button-border-color:          $color-primary-dark;
$site-header-logo-text-color:              $color-primary-dark;

$site-mobile-nav-header-bg-color:          $color-primary-dark;
$site-mobile-nav-header-text-color:        $color-primary-dark;
$site-mobile-nav-header-lang-switch:       $color-primary-dark;
$site-mobile-nav-header-lang-globe:        $color-primary-dark;
$site-mobile-nav-header-button-bortfrt:    $color-primary-dark;

$site-menu-indicator-color:                $color-primary-dark;

$site-submenu-color:                       $color-primary-dark;
$site-submenu-bg-color:                    $color-primary-white;
$site-submenu-active-color:                $color-primary-tint-5;


$site-header-basket-background:            $color-primary-dark;
$site-header-basket-icon-color:            $color-primary-dark;
$site-header-basket-count-background:      $color-util-red;
$site-header-basket-count-color:           $color-primary-white;

$site-header-notification-text-color:      $color-primary-dark;
$site-header-notification-button-color:    $color-primary-tint-5;


// --------------------------------------------------------------------------
// Pickadate Datepicker:                   scss/components/_pickadate.scss
// --------------------------------------------------------------------------

$pickadate-left-bg-color:                  $color-primary-tint-4;
$pickadate-day-bg-color:                   $color-primary-tint-4;
$pickadate-day-text-color:                 $color-primary-white;
$pickadate-day-today-text-color:           $color-primary-dark;
$pickadate-picker-text-color:              $color-primary-dark;
$pickadate-picker-fucos-bg-color:          $color-primary-tint-5;
$pickadate-picker-close-color:             $color-primary-white;



// --------------------------------------------------------------------------
// Sellingpoins:                           scss/components/_sellingpoints.scss
// --------------------------------------------------------------------------

$sellingpoints-bg-color:                   $color-primary-dark;
$sellingpoints-trianble-1-bg-color:        $color-primary-white;
$sellingpoints-trianble-2-bg-color:        $color-primary-white;
$sellingpoints-carousel-arrow-color:       $color-primary-white;



// --------------------------------------------------------------------------
// Page sections:                          scss/components/_page-sections.scss
// --------------------------------------------------------------------------

$section-blank-color:                       $color-primary-dark;
$section-blank-bg-color:                    $color-primary-white;

$section-light-color:                       $color-primary-dark;
$section-light-bg-color:                    $color-primary-tint-3;

$section-dark-color:                       $color-primary-dark;
$section-dark-bg-color:                    $color-primary-tint-2;

$section-alternative-bg-color:              $color-primary-tint-7;

$section-footer-text-color:                 $color-primary-white;
$section-image-overlay-bg-color:            $color-primary-dark;
$section-description-text-color:            $color-primary-dark;

// --------------------------------------------------------------------------
// Detail page                 css/sections/_detail_page.scss
// ----------------------------------------------------------------------------
$detail-link-color:                         $color-primary-tint-5;
$detail-link-color-dark:                    $color-primary-tint-5;
$detail-link-color-light:                   $color-primary-white;

// --------------------------------------------------------------------------
// Section - Apps:                         scss/sections/_apps.scss
// --------------------------------------------------------------------------

$apps-trianble-1-bg-color:                 $color-primary-tint-2;



// --------------------------------------------------------------------------
// Section - Infobox:                      scss/sections/_infobox.scss
// --------------------------------------------------------------------------

$infobox-trianble-1-bg-color:              $color-primary-dark;
$infobox-trianble-2-bg-color:              $color-primary-tint-3;



// --------------------------------------------------------------------------
// Section - News:                         scss/sections/_news.scss
// --------------------------------------------------------------------------

$news-bg-color:                            $color-primary-tint-2;
$news-trianble-1-bg-color:                 $color-primary-white;



// --------------------------------------------------------------------------
// Section - Panorama:                     scss/sections/_panorama.scss
// --------------------------------------------------------------------------

$panorama-bg-color:                        $color-primary-tint-2;
$panorama-intro-bg-color:                  rgba($color-primary-white, .8);
$panorama-caption-header-bg-color:         $color-primary-transparent;
$panorama-caption-header-text-color:       $color-primary-dark;
$panorama-caption-description-bg-color:    $color-primary-transparent;
$panorama-caption-description-text-color:  $color-primary-dark;
$panorama-caption-jumpto-arrow-color:      $color-primary-dark;



// --------------------------------------------------------------------------
// Section - People:                       scss/sections/_people.scss
// --------------------------------------------------------------------------

$people-text-color-present:                $color-primary-tint-5;
$people-text-color-not-present:            $color-primary-gray-3;



// --------------------------------------------------------------------------
// Section - Repair:                       scss/sections/_repair.scss
// --------------------------------------------------------------------------

$repair-bg-color:                          $color-primary-tint-3;
$repair-trianble-1-bg-color:               $color-primary-tint-6;
$repair-modal-close-button:                $color-primary-accent;
$repair-play-button:                       $color-primary-white;
$repair-play-button-bg:                    $color-util-red;
$repair-tooltip-bg-color:                  $color-primary-blackgray;


// --------------------------------------------------------------------------
// Section - Servicegrid:                  css/sections/_servicegrid.scss
// --------------------------------------------------------------------------

$servicegrid-header-text-color:            $color-primary-dark;
$servicegrid-bg-color:                     $color-primary-white;
$servicegrid-trianble-1-bg-color:          $color-primary-white;
$servicegrid-service-text-color:           $color-primary-dark;
$servicegrid-col-border-color:             $color-primary-tint-4;
$servicegrid-crosshair-cross-color:        $color-primary-dark;
$servicegrid-crosshair-bg-color:           $color-primary-white;
$servicegrid-crosshair-color:              $color-primary-dark;
$servicegrid-comming-soon-color:           $color-primary-gray-5;

$servicegrid-bg-color-inversed:            $color-primary-tint-3;
$servicegrid-header-text-color-inversed:   $color-primary-dark;
$servicegrid-service-text-color-inversed:  $color-primary-dark;
$servicegrid-crosshair-bg-color-inversed:  $color-primary-tint-3;
$servicegrid-crosshair-color-inversed:     $color-primary-dark;
$servicegrid-crosshair-cross-color-inversed:$color-primary-white;
$servicegrid-trianble-1-bg-color-inversed:  $color-primary-white;
$servicegrid-col-border-color-inversed:     $color-primary-tint-4;

// Alternative color scheme for the service icons grid:
$servicegrid-alt-bg-color:                 $color-primary-tint-6;
$servicegrid-alt-trianble-1-bg-color:      $color-primary-white;
$servicegrid-alt-service-text-color:       $color-primary-white;
$servicegrid-alt-col-border-color:         $color-primary-tint-3;
$servicegrid-alt-crosshair-cross-color:    $color-primary-tint-6;
$servicegrid-alt-crosshair-bg-color:       $color-primary-tint-6;


// --------------------------------------------------------------------------
// Section - Repair:                       scss/sections/_repair.scss
// --------------------------------------------------------------------------

$repair-bg-color:                          $color-primary-tint-3;
$repair-trianble-1-bg-color:               $color-primary-tint-6;

// ----------------------------------------------------------------------------
// Toatst -                                 css/components/_toast.scss
// ----------------------------------------------------------------------------
$toast-bg-color:                           $color-primary-tint-5;
$toast-color:                               $color-primary-white;

// ----------------------------------------------------------------------------
// Profile pages -                          css/pages/_profile.scss
// ----------------------------------------------------------------------------
$profile-menu-mobile-bg-color:              $color-primary-tint-2;
$profile-menu-mobile-color:                 $color-primary-dark;
$profile-menu-toggle-bg-color:              $color-primary-tint-2;
$profile-menu-toggle-color:                 $color-primary-white;

// ----------------------------------------------------------------------------
// Faq collapsible -                       css/components/_collapsibles.scss
// ----------------------------------------------------------------------------
$collapsible-color:                        $color-primary-white;
$collapsible-title-color:                  $color-primary-white;
$collapsible-icon-color:                   $color-primary-dark;
$collapsible-bg-color:                     $color-primary-transparent;


$alternative-color:                        $color-primary-dark;
$alternative-title-color:                  $color-primary-white;

// ----------------------------------------------------------------------------
// Faq icon -                              css/components/_page-sections.scss
// ----------------------------------------------------------------------------
$faq-icon-color:                           $color-primary-white;

// ----------------------------------------------------------------------------
// Pagination -                        css/components/_pagination.scss
// ----------------------------------------------------------------------------

$pagination-color:                          $color-primary-white;
$pagination-bg-color:                       $color-primary-tint-6;
$pagination-active-color:                   $color-primary-white;
$pagination-hover-color:                    $color-primary-white;

// ----------------------------------------------------------------------------
// Cookie Consent banner                      css/components/_ccbar.scss
// ----------------------------------------------------------------------------
$cc-banner-bg-color:$color-primary-white;
$cc-banner-color:$color-primary-dark;
$cc-banner-border-color:$color-primary-dark;
$cc-banner-text-color: $color-primary-dark;
$cc-banner-button-bg-color: $color-primary-dark;
$cc-banner-button-color:$color-primary-white;

// ----------------------------------------------------------------------------
// Service page                      css/pages/_service-page.scss
// ----------------------------------------------------------------------------
$service-product-amount-border:             $color-primary-dark;
$service-product-sidemenu-color:            $color-primary-dark;
$service-product-disabled-menu-color:       $color-primary-tint-7;
$service-product-grid-item-bg-color:        $color-primary-tint-1;
$service-product-image-bg-color:            $color-primary-white;
$service-product-btn-color:                 $color-primary-tint-4;
$service-product-btn-txt-color:             $color-primary-white;
$service-product-btn-hover-color:           $color-primary-white;
$service-product-checkbox-background:       $color-primary-tint-5;

// Service detail btns -                          css/components/_detail_page.scss
// ----------------------------------------------------------------------------
$service-btn-border-color:              $color-primary-dark;
$service-btn-text-color:                $color-primary-dark;
$service-btn-background-color:          $color-primary-dark-tint;
$service-btn-dark-color:                $color-primary-dark;
$service-collapsible-header:            $color-primary-tint-5;

// ----------------------------------------------------------------------------
// Floor service                          css/section/_floorservice.scss
// ----------------------------------------------------------------------------
$card-floorservice-bullet-color:           $color-primary-dark;
$card-floorservice-label-text-color:       $color-primary-dark;
$card-floorservice-nav-label-text-color:   $color-primary-tint-7;
$card-floorservice-nav-label-icon-color:   $color-primary-dark;
$card-floorservice-nav-label-edit-icon-color:  $color-primary-dark;
$card-floorservice-nav-icon-color:         $color-primary-dark;
$card-floorservice-selected-item-background-color: $color-primary-white;
$floorservice-nav-text-color:                       $color-primary-tint-4;
$floorservice-nav-text-color-active:                $color-primary-dark;

// Repair Request Block                      css/sections/_repair.scss
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
$repair-request-step-line-background-color: $color-primary-dark;
$repair-request-step-block-border-color: $color-primary-dark;
$repair-request-step-block-color: $color-primary-dark;
$repair-request-step-block-background-color: $color-primary-white;
$repair-request-btn-border-color: $color-primary-tint-5;
$repair-request-btn-color: $color-primary-tint-5;
$repair-request-edit-border-color: $color-primary-tint-5;
$repair-request-step-border-color: $color-primary-tint-5;

// Notification Element
// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------
$notification-element-background: $color-primary-white;
$notification-element-background-unread: $color-primary-tint-3;
$notification-left-border-unread: $color-primary-tint-5;
$notification-divider: $site-header-bg-color;

// shop pages radio button
$shop-step-input-field:                             $color-primary-dark;
$shop-step-button-color:                            $color-primary-dark;

// sticky header message
$sticky-header-message-bg-color:    $color-primary-dark;
$sticky-header-message-color:       $color-primary-white;

// ----------------------------------------------------------------------------
// Timeline                         css/components/_timeline.scss
// ----------------------------------------------------------------------------
$timeline-primary: $color-primary-accent;
$timeline-primary-light: $color-primary-white;
$timeline-background: $color-primary-tint-2;
$timeline-border: #9A9B98;
$timeline-alternate-text: #727272;
$timeline-url-color: $link-color;
$timeline-gradient-background:url('/images/gradient.png');
$timeline-preview-quote: $color-primary-tint-3;
$timeline-link-color: $color-primary-tint-6;
$timeline-reply-username-color: $color-primary-tint-5;
$timeline-message-background: $color-primary-tint-1;
$timeline-send-button-color: $color-primary-gray-1;

// ----------------------------------------------------------------------------
// Sticky navigation bottom
// ----------------------------------------------------------------------------
$bottom-navigation-background-color: $color-primary-tint-3;
$bottom-navigation-color: $color-primary-dark;

// ----------------------------------------------------------------------------
// Repair block
// ----------------------------------------------------------------------------

$repair-block-background-color: $color-primary-tint-1;
$repair-block-heading-color: $color-primary-tint-6;
$repair-block-text-color: $color-primary-dark;
$repair-block-status-0: $color-primary-gray-2;
$repair-block-status-1: $color-primary-tint-6;
$repair-block-status-2: #d8a004;
$repair-block-status-3: $color-primary-tint-6;
$repair-block-status-4: $color-primary-dark;
$repair-block-row-hover: $color-primary-tint-3;

@import '../includes/typography';             // Include typographic styles for displaying custom webfonts

@import '../application'; // Include the main stylsheet

.site-navigation{
    ul.right {

        .active{
            a{
                font-weight:700;
            }
        }
    }
}

.shrink {
    .site-navigation {
        .brand-logo{
            @media #{$large-and-up} {
                width: 120px;
                line-height:62px;
                height: 62px;
                margin-top:0px;
            }
        }
    }
}


.page-section{

    &.panorama-intro{
        .section-bg-overlay {
            opacity: 0.4;
            -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
            filter: alpha(opacity=40);
            -moz-opacity: 0.4;
            -khtml-opacity: 0.4;
        }
    }

    &.light {
        .overlay .btn.btn-border {
            border: $btn-border-width solid $color-primary-dark;
            background-color: $btn-dark-btn-border-bg-color;
            color: $color-primary-dark;
        }
    }
    &.dark {
        .overlay .btn.btn-border {
            border: $btn-border-width solid $color-primary-white;
            background-color: $btn-light-btn-border-bg-color;
            color: $color-primary-white;
        }
    }
}
.timelineContainer {
    #timelineSegment {
        .message {
            span.userName {
                color: $color-primary-dark;
            }
            .responses {
                p.halfMargin {
                    color: $color-primary-dark;
                    .datePart {
                        color: $timeline-alternate-text;
                    }
                }
            }
        }
    }
}
