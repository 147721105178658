$pj-white:       #FFFFFF;
$pj-green-1:     #F4F6F0;
$pj-green-2:     #E4E9DC;
$pj-green-3:     #DAE1CE;
$pj-green-4:     #A4B585;
$pj-green-5:     #577625;
$pj-blue:        #002E47;

$headings-font-family: 'Variable Black';
$headings-font-weight: 'regular';
$headings-line-height: 2rem;

$font-variable:            'Variable';
$font-futura:              'Futura';
$font-futura-classic:      'Futura-Classic';



// Global green colorscheme
$color-primary-black:          rgba(0, 0, 0, 1);
$color-primary-white:          rgba(255, 255, 255, 1);
$color-primary-tint-1:         rgba(244, 246, 240, 1);
$color-primary-tint-2:         rgba(228, 233, 220, 1);
$color-primary-tint-3:         rgba(218, 225, 206, 1);
$color-primary-tint-4:         rgba(164, 181, 133, 1);
$color-primary-tint-5:         $pj-green-5;
$color-primary-tint-6:         rgba(121, 145, 80, 1);
$color-primary-tint-7:         rgba(119, 176, 187, 1);
$color-primary-accent:       $color-primary-tint-6; //  rgba(87, 118, 37, 1);
$color-primary-dark:           rgba(0, 46, 71, 1);
$color-primary-dark-3:         rgba(0, 0, 0, 0.3);
$color-primary-error:          rgba(128, 0, 0, 1);
$color-primary-transparent:    rgba(0, 0, 0, 0);


$color-primary-dark-tint:      rgba(0, 46, 71, 0.13);
$color-primary-blackgray:      rgba(53,53,53,1);

$color-primary-gray:           darken($color-primary-white, 10%);
$color-primary-gray-1:         darken($color-primary-white, 20%);
$color-primary-gray-2:         darken($color-primary-white, 30%);
$color-primary-gray-3:         darken($color-primary-white, 40%);
$color-primary-gray-4:         darken($color-primary-white, 50%);
$color-primary-gray-5:         darken($color-primary-white, 60%);
$color-primary-gray-6:         darken($color-primary-white, 70%);
$color-primary-gray-7:         darken($color-primary-white, 80%);
$color-primary-gray-8:         darken($color-primary-white, 90%);
$color-primary-gray-9:         darken($color-primary-white, 100%);

$color-primary-brown-1:        rgba(199, 174, 139, 1);




$color-util-yellow:            rgba(255, 238, 0, 1);
$color-util-amber:             rgba(255, 165, 0, 1);
$color-util-red:               rgba(255, 0, 0, 1);
$color-util-maroon:            rgba(128, 0, 0, 1);
$color-util-blue:              rgba(0, 0, 255, 1);
$color-util-green:             rgba(0, 128, 0, 1);
$color-alert:                  #E30100;
// // Pontsteiger colorscheme
// $color-primary-white:      rgba(255, 255, 255, 1);
// $color-primary-tint-1:     rgba(241, 241, 241, 1);
// $color-primary-tint-2:     rgba(171, 171, 171, 1);
// $color-primary-tint-3:     rgba(144, 144, 144, 1);
// $color-primary-tint-4:     rgba(111, 111, 111, 1);
// $color-primary-tint-5:     rgba(129, 138, 116, 1);
// $color-primary-accent:     rgba(199, 174, 139, 1);
// $color-primary-dark:       rgba(0, 0, 0, 1);

