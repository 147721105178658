.page-section {
    &.service-products{
        .coupon-form{
            form{
                .coupon-code{
                    border:1px solid $color-primary-dark;
                    padding: 0px 4px;
                    margin-bottom: 0;
                }
                .coupon-link{
                    background-color: transparent;
                    border: 0;
                    text-decoration: underline;
                    margin: 0;
                    padding: 0;
                    color: $color-primary-dark;
                }
                .input-field{
                    margin-top:0;
                }
            }
        }
        .coupon-description{
            p{
                margin-top:0;
            }
        }
        .basket-total{
            background-color: $color-primary-tint-1;
            line-height:2rem;
        }
    }

    position: relative;
    width: 100%;
    padding: 3rem 0;
    overflow: hidden;

    @media #{$small-and-down} {
        padding: 2rem 0;
    }

    &.overflow-visible{
        overflow:visible;
    }
    p,table,ul,ol{
        font-size: 1.333rem;
        width:auto;
    }

    ul, ol{
        padding-left:20px;
        li{
            list-style-type: square;
        }
    }
    ol{
        li{
            list-style-type: decimal;
        }
    }


    table{
        margin-bottom:20px;
        
        td{
            padding:10px 5px; 

            @media #{$extra-large-and-up} {   
                padding:15px 8px;  
            }
        }

        &.responsive{
            @media #{$medium-and-down} {   
                .table__heading {
                    display: none;
                }
                .table__content {
                    display: block;
                    padding: 0rem 0;
                }
                .table__row {                    
                    padding: 1rem 0;
                    display: block!important;
                    border-bottom: 4px solid #d0d0d0;
                }
                .table__content:before {
                    content: attr(data-heading);
                    display: inline;
                    // width: 8.5rem;
                    // margin-right: .5rem;
                    font-weight: 500;
                }
            }
        }
    }
    .center-align table{
        display: inline-block;
       
    }
   
    p.center-align{
        display:inline-block;
    }
    

    @media #{$large-and-up} {   
        p{
            max-width:80%;         
            
        }
    }   

    #bundleIframe{
        min-height:100vh;
    }
     
    .section-header {
        [class^="icon-"]{
            font-size: 75px;
            line-height: 1em;
        }

        .section-title {
            margin: 0;
            padding: 0 0 3rem;
            @media #{$small-and-down} {
                padding: 0 0 2rem;
            }
            /*
            &:last-child{
                margin-bottom: 0;
                padding-bottom:0;
            }*/
        }

        .section-description {
            margin-top: 0;
            font-size: 2.133rem;

            a{
                text-decoration: underline;
                color:$section-description-text-color;

                &:hover{
                    text-decoration: none;
                }
            }

            p:first-child{
                margin-top:0;
            }
        }

    }


    &.cms-block {
        .section-header {           
            .section-title {
                padding: 0;
            }
        }
    }

    .container.overlay {
        position: relative;
         z-index: 99999;
    }

    .section-footer {
        padding: 2rem 0 1rem;
        color: $section-footer-text-color;
    }

    &.blank {
        color: $section-blank-color;
        background-color: $section-blank-bg-color;
    }

    &.light {
        color: $section-light-color;
        background-color: $section-light-bg-color;
    }

    &.dark {
        color: $section-dark-color;
        background-color: $section-dark-bg-color;
    }

    &.alternative {
        color: $alternative-color;
        background-color: $section-alternative-bg-color;

        .top-icon{
            max-height:150px;
            max-width:150px;
            .a{
                fill:$faq-icon-color;
            }
            .b{
                fill:none;
                stroke:$faq-icon-color;
                stroke-width:4px;
            }
        }

        .section-title{
            color:$alternative-title-color;
        }

        .button_block{
            padding:5rem 0 5rem 0;

            .btn{
                background-color:$btn-alternative-bg-color
            }
        }
    }

    .section-bg {

        .section-bg-image,
        .section-bg-overlay {
            position: absolute;
            top: 0%;
            left: 0%;
            width: 100%;
            height: 100%;
            background-position: 50% 50%;
            background-size: cover;
            background-repeat:no-repeat;
        }

        .section-bg-overlay {
            z-index: 2;
            background-repeat: no-repeat;
            background-position: 0 0;
            opacity: 0.55;
        }

        .section-bg-image {

            z-index: 1;

            &.bg-top { background-position: 0% 50%; }
            &.bg-middle { background-position: 50% 50%; }
            &.bg-bottom { background-position: 100% 50%; }

            &.bg-blur { @include filter-blur(2px); }
            &.bg-blur-1px { @include filter-blur(1px); }
            &.bg-blur-2px { @include filter-blur(2px); }
            &.bg-blur-4px { @include filter-blur(4px); }

        }

        .section-image-overlay {
            z-index: 2;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0; 
            background-color: $section-image-overlay-bg-color;
            opacity: 0.9;
        }
    }

    .information{
        display:inline-block;
        background-color:$form-information-bg-color;
        padding: 0.6667rem;
        color:$form-information-text-color;
        display: inline-block;
        border-radius: 10px;
        margin-top: 0.7em;
        p{
            margin:0;
            max-width:100%;
        }
        &.spacing{
            margin-bottom:20px;
        }
    }

    .information-booking{
        background-color:$form-information-booking-bg-color;
        color:$form-information-booking-text-color;    
        max-width:100%;
        &.spacing{
            margin-bottom:20px;
        }

        text-align:center;
        @media #{$medium-and-up} {
            text-align:left;
            max-width:100%;            
        }
    }

    #service_booking_notes{
        height:5rem;
        border: 1px solid $color-primary-dark;
    }

    .cart-page{

        #service_booking_notes{
            height:10rem;
        }

    }

    .underline{
        border-bottom:1px solid $color-primary-dark;
        width:100%;
    }

    &.service-products{
        form .input-field label.indent{
            margin:0;
        }
    }
}

.pickadate-open .page-section .container.overlay{
    z-index: 99999999;
}