// -------------------------------------
//   People section styling
// -------------------------------------

.page-section.people {
    .col{
        @media #{$medium-and-up} {
            display: inline-block;
            float: none;
        }
    }
    .person {

        margin-bottom: 3rem;
        line-height: 1rem;
        max-width: 145px;
        display: inline-block;
        width: 100%;

        .person-image{
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            padding-top: 100%;
            filter: grayscale(1);
            transition: all .15s linear;
        }
        .person-title {
            margin-bottom: 0;
            font-size: 2.133rem;
            font-weight: normal;
        }

        .present {
            color: $people-text-color-present;
            font-weight: bold;
        }

        .not-present {
            color: $people-text-color-not-present;
            font-weight: bold;
        }

    }

}
