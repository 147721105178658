section.detail-page{       
        
        h1{
            font-size:2.2rem;
            margin:1.14rem 0 .912rem;
            text-align:center;      

            @media #{$medium-and-up} {   
                font-size:3rem;
            }

            @media #{$large-and-up} {   
                font-size:3.333rem;
            }
        }
        
        .icon{
            margin-bottom: .5rem;
        }
        .date {
            font-size: 1.5rem;
            margin-bottom: 1.5rem;
            text-align:center;
        }
    
        .image {
            height: 0px;
            padding-top:60%;
            overflow: hidden;
        }
    
        .description {
            font-weight:bold;
            font-size:1.133rem;
        }
        
        .content{
            font-size:1.133rem;
            p{
                max-width: 100%;

                &:first-child{
                    margin-top:0px;
                }
            }

            a{
                color:$detail-link-color;
                text-decoration:underline;
                &:hover{
                    text-decoration:none;
                }
                &.btn{
                    text-decoration:none;
                }
            }
            
            b,strong{
                font-weight:bold;
            }
    
            ul{
                list-style-type: square;
                li{
                    list-style-type: square;
                    margin:7px 0 7px 25px;
                }
            }
    
            ol{
                padding:0;
                li{
                    margin:7px 0 7px 20px;
                }
            } 

            img{
                max-width: 100%;
                height: auto !important;
            }
        }

        &.dark{
            .content{
                a:not(.btn){
                    color:$detail-link-color-light;
                }
            }
        }

        &.blank{
            .content{
                a:not(.btn){
                    color:$detail-link-color-dark;
                }
            }
        }

        &.service-detail{
            header{
                color:$color-primary-white;
            }

            .content{
                color:$color-primary-white;
            }
        }

        .image-gallery{
            display: flex;
            flex-wrap: wrap;
            margin-left: -1%;
            margin-right: -1%;
            .img-flex-wrapper{
                width:23%;
                display: inline-block;
                flex: 1 1 23%;
                margin: 1%;
                .image{
                    height:0;
                    display: block;
                }
            }
        }

    }

    section.service-blocks{

        .product-card{
            .btn{
                &.btn-border{
                    border-color:$service-btn-border-color;
                    color:$service-btn-text-color;
                }

                &.btn-dark{
                    background-color:$service-btn-background-color;
                    color:$service-btn-dark-color;
                }
            }
        }
    }
