main .floorservice{
    .steps{


        .nav{
            ul{
                font-size:1.5rem;

                li{
                    //opacity: 0.5;
                    //filter: alpha(opacity=50);
                    color:$floorservice-nav-text-color;
                    cursor:pointer;
                    position:relative;
                    display:inline-block;                    

                    .edit{
                        width:20px;
                        height:20px;
                        display:inline-block;
                        background:url('/assets/dist/svg/sprite.svg#tegel');
                        background-position: center;
                        background-size:contain;
                        margin-left:5px;
                        fill: $card-floorservice-nav-label-edit-icon-color; 
                    }

                    span{
                        float:left;
                        color:lighten($card-floorservice-nav-label-text-color,5%);
                        display:block;
                        width:calc(100% - 25px);
                    }

                    img{                        
                        width:50px;
                        height:50px;
                        fill: $card-floorservice-nav-label-icon-color;                   
                    }

                    svg{                        
                        width:50px;
                        height:50px;
                        fill: $card-floorservice-nav-label-icon-color;                         
                    }

                    .selected-option{
                        display:none;
                        @media #{$large-and-up} {    
                        display:block;
                        }
                    }

                    &.active{
                        //opacity: 1;
                        //filter: alpha(opacity=100);
                        color:$floorservice-nav-text-color-active;

                        span{
                            color:$card-floorservice-nav-label-text-color;
                        }
                    }

                    &#step-4{
                        .selected-option{
                            span{
                                margin-left:10px;
                                display:inline-block;
                                width:80%;
                            }

                            span span:before{
                                content:'';
                                position:relative;
                                display:block;
                                width:10px;
                                height:10px;
                                background:$card-floorservice-nav-label-text-color;
                                border-radius: 50%;
                                left:-20px;
                                color:$card-floorservice-nav-label-text-color;
                                top:25px;                                
                            }
                        }                        
                    }

                    
                }

                li:hover{
                    opacity:1;
                }

                li:before{
                    font-family: 'Material Icons';
                    font-style: normal;
                    font-weight: 400;
                    content:'\E1C8';
                    position:absolute;
                    left:-30px;
                    @media #{$large-and-up} {    
                        left:-40px;
                    }
                    top:3px;
                    display:block;
                    transform: rotate(-45deg);
                    color:$card-floorservice-nav-icon-color;
                }
            }
        }


        .step{
            h1,h3{
                width:100%;
                text-align:left;
                margin-left:5px;
            }

            b{
                width:100%;
                font-size: 1.5rem;
                text-align:center;
                display:block;
            }

            h1{
                font-size:3.125rem;
                margin: 1.1rem 0 1.68rem 5px;
            }

            h3{
                font-size: 2.25rem;
                margin-bottom:2.25rem;
                font-weight:bold;
            }

            h4{
                margin-bottom:2rem;
            }

            textarea{
                height:200px;
            }

            [type=radio].with-gap:checked+label:after, [type=radio]:checked+label:after{
                background-color: $card-floorservice-bullet-color;
                border-color: $card-floorservice-bullet-color;
            }

            label{
                font-size: 1.5rem;
                margin-bottom:1rem;
                color: $card-floorservice-label-text-color;
            }

            .endPrice{
                display:inline-block;
                width:auto;
            }

            p{
                &.center{
                    max-width:100%;
                }
            }
            .floorservice-btn{
                display: none;
            }

            

            display:none;
            &.active{
                display:block;
            }

            .colors{
                margin:0;
                padding:0;
                list-style:none;
                li{
                    margin:10px;;
                    padding:0;
                    list-style:none;
                    text-align:center;
                    height:230px;
                    width:190px;
                    float:left;
                    display:none;

                    &.warning{
                        display:block;
                        width:100%;
                        text-align:left;
                        margin:0;
                    }

                    [type=radio]:not(:checked)+label:after, [type=radio]:not(:checked)+label:before, [type=radio].with-gap:checked+label:after,[type=radio]:checked+label:after{
                        display:none;
                    }

                    label{
                        height:190px;
                        width:190px;
                        cursor:pointer;
                        padding:0;
                        margin-bottom:0px;
                        display:block;
                    }
                                        
                    P{
                        &.center{
                            text-align:center;
                        }
                    }

                    .desc{
                        font-size:1rem;
                        border-top:5px solid $card-img-border-color;
                        display:block;
                        width:100%;
                    }
                }
            }

            &#step-1-content{
                .card{
                    img{
                        max-width:200px;
                        max-height:100px;
                    }
                }
            }

            &#step-1-content{
                input[type="radio"]:checked + label{
                    .card-image{
                        border:3px solid $card-img-border-color;
                        border-bottom:10px solid $card-img-border-color;
                    }
                }
            }

            &#step-2-content{
                input[type="radio"]:checked + label{
                    border:3px solid $card-img-border-color;
                }
            }

            &#step-3-content{
                input[type="radio"]:checked + label{
                    .card-image{
                        border:3px solid $card-img-border-color;
                        border-bottom:10px solid $card-img-border-color;
                    }
                }
            }

            &#step-4-content{
                .btn.btn-small{
                    float:right;
                }
            }

            &#step-1-content,&#step-5-content {
                @media all and (min-width: 500px) {
                    p {
                        width: 100%;
                    }
                }
            }


        }

        .show{
            display:block !important;
        }

        .formsendmessage{
            text-align:center;

            h1{
                font-size:3.125rem;
            }

            h1,p{
                width:100%;
                max-width:100%;
                display:inline-block;
                text-align:center;
                
            }
        }

        .floorformerrors{
            width:100%;
            padding-left: 40px;
            margin-top: 20px;
        }
    }
}

@media all and (max-width: 500px) {
    main .floorservice .steps{
        .nav {
            display: none;
        }

        .step {
            h1 {
                font-size: 2rem;
            }
            h3 {
                float: left;
                width: 100%;
                padding-top: 5px;
                padding-bottom: 5px;
                text-align: center;
                background-color: black;
                color: #fff;
                font-size: 2rem;
                font-weight: normal;               
            }

            .card-image {
                display: inline-block;
                position: relative;
                width: 40%;
                text-align: center;

                svg{
                    width: 100%;
                }
                .specs{
                    width: 100%;
                }
            }

            .card-content {
                display: none;
            }
            .card-floorservice {
                margin-bottom: 0;

                p.floorservice-btn {
                    display: inline-block;
                    position: absolute;
                    width: 58%;
                    border: 1px solid black;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    margin-top: 60px;
                    font-weight: normal;
                }
            }
        }
    }
}
