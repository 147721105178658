// -------------------------------------
//   Testimonials carousel section styling
// -------------------------------------

section.testimonials-carousel {

    position: relative;
    overflow: hidden;

    .item {
        position: relative;
        img {
            position: absolute;
            z-index: 1;
            width: 100%;
        }
        .text {
            position: absolute;
            z-index: 2;
            width: 100%;
            // max-width: 80%;
            text-align: center;
            padding-top: 8%;
            h2 {
                font-weight: bold;
            }
            p {
                font-size: 1.3rem;
            }
            h2, p {
                width: 50%;
                max-width: 80%;
                margin: 0px auto;
            }
        }
    }


    .triangle-1 {
        position: absolute;
        z-index: 1;
        left: -316px;
        top: -259px;
        @include triangle-north-west( #FFF, 400px );
    }

    .triangle-2 {
        position: absolute;
        z-index: 1;
        right: -15px;
        bottom: -65px;
        @include triangle-south-east( #FFF, 100px );
    }


    .carousel-nav {

        position: absolute;
        top: 110px;

        .angle-left,
        .angle-right {
            cursor: pointer;
            z-index: 90;
            position: absolute;
            width: 100%;
            margin: 20px auto 0px auto;
            display: block;
            font-size: 8px;
            fill: currentColor;
            max-height: 50px;
            max-width: 50px;
        }

        .angle-left {
            left: 50px;
            color: $pj-blue;
        }

        .angle-right {
            right: 50px;
            color: #FFF;
        }

    }

}
