// -------------------------------------
//   News section styling
// -------------------------------------

.page-section.news {

    .triangle-1 {
        @include triangle-south-east($news-trianble-1-bg-color, 200px);
        position: absolute;
        z-index: 1;
        right: -34px;
        bottom: -295px;
    }

    @media #{$medium-and-up} {
        .triangle-1 {
            bottom: -132px;
        }
    }

}
