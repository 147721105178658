@import '../../../../../node_modules/lightbox2/dist/css/lightbox.css';
.lightbox {

    .lb-data .lb-number{
        display:none!important;
    }
    .lb-image{
        border:none;
        border-radius: 0px;
    }

    .lb-nav a.lb-prev{
        background: url(../../../../images/prev.png);
        background-repeat: no-repeat;
        background-position: left;
    }

    .lb-nav a.lb-next{
        background: url(../../../../images/next.png);
        background-repeat: no-repeat;
        background-position: right;
    }

    .lb-data .lb-close {
        background: url(../../../../images/close.png) top right no-repeat;
    }

    .lb-cancel {
        background: url(../../../../images/loading.gif) no-repeat;
      }


}