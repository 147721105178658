// -------------------------------------
// Site Footer styling
// -------------------------------------

.site-footer {

    overflow: hidden;
    background-color: $color-primary-tint-3;
    background-image: url('/images/bg-footer.svg');
    background-position: 100% 0;
    background-size: cover;

    .tagline {
        text-align: center;
        margin: 5.667rem 0 5.667rem 0;
    }

    a {
        color: $color-primary-dark;
    }

}
