.pagination{

    li:not(.disabled){
        height:40px;
        padding: 0 10px;
        line-height:40px;
        font-size:1.333rem;
        &.active{
            background-color:$pagination-bg-color;
            color:$pagination-hover-color; 
        }

        &:hover{
            background-color:$pagination-bg-color;
            color:$pagination-active-color;
            a{
                color:$pagination-active-color;
            }
        }
    }
}