.cms-block {
    background-size: cover !important;
    background-position: center !important;
    .textrow{
        img{
            max-width: 100%;
            height: auto;
            padding-bottom: 40px;
        }
    }
}

.light-text{
    color: #fff;
}
