// -------------------------------------
//   Apps section styling
// -------------------------------------

.page-section.apps {

    .container {
        position: relative;
    }

    .triangle-1 {
        @include triangle-north-west($apps-trianble-1-bg-color, 100px);
        position: absolute;
        z-index: -1;
        top: -112px;
        left: -14px;
    }

    .section-header {

        .section-title {
            margin: 3rem 0;
            font-size: 1.3rem;
        }

    }

    .items {

        // h2.item-title
        .item-title {
            font-size: 1.3rem;
        }

        // p.item-description
        .item-description {
            font-size: 1rem;
        }

        // img.item-image
        .item-image {
            margin-left: 1rem;
            float: right;
        }

    }

}
