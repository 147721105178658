@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
//@import url("//hello.myfonts.net/count/353b10");


// Variable Light (300)
@font-face {
    font-family: 'Variable';
    src: url('../../fonts/Variable-Light.eot');
    src: url('../../fonts/Variable-Light.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/Variable-Light.woff2') format('woff2'),
         url('../../fonts/Variable-Light.woff') format('woff'),
         url('../../fonts/Variable-Light.ttf') format('truetype'),
         url('../../fonts/Variable-Light.svg#Variable-Light.svg') format('svg');
    font-weight: light;
    font-style: normal;
}

// Variable Normal (400)
@font-face {
    font-family: 'Variable';
    src: url('../../fonts/Variable-Bold.eot');
    src: url('../../fonts/Variable-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/Variable-Bold.woff2') format('woff2'),
         url('../../fonts/Variable-Bold.woff') format('woff'),
         url('../../fonts/Variable-Bold.ttf') format('truetype'),
         url('../../fonts/Variable-Bold.svg#Variable-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Variable Bold (700)
@font-face {
    font-family: 'Variable';
    src: url('../../fonts/Variable-Black.eot');
    src: url('../../fonts/Variable-Black.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/Variable-Black.woff2') format('woff2'),
         url('../../fonts/Variable-Black.woff') format('woff'),
         url('../../fonts/Variable-Black.ttf') format('truetype'),
         url('../../fonts/Variable-Black.svg#Variable-Black') format('svg');
    font-weight: bold;
    font-style: normal;
}

// Futura Classic Light
@font-face {
    font-family: 'Futura-Classic';
    src: url('../../fonts/FuturaClassicLight.eot');
    src: url('../../fonts/FuturaClassicLight.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaClassicLight.woff2') format('woff2'),
         url('../../fonts/FuturaClassicLight.woff') format('woff'),
         url('../../fonts/FuturaClassicLight.ttf') format('truetype'),
         url('../../fonts/FuturaClassicLight.svg#wf') format('svg');
    font-weight: 300;
    font-style: normal;
}

// Futura Classic Normal
@font-face {
    font-family: 'Futura-Classic';
    src: url('../../fonts/FuturaClassicNormal.eot');
    src: url('../../fonts/FuturaClassicNormal.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaClassicNormal.woff2') format('woff2'),
         url('../../fonts/FuturaClassicNormal.woff') format('woff'),
         url('../../fonts/FuturaClassicNormal.ttf') format('truetype'),
         url('../../fonts/FuturaClassicNormal.svg#FuturaClassicNormal') format('svg');
    font-weight: 400;
    font-style: normal;
}

// Futura Classic Bold
@font-face {
    font-family: 'Futura-Classic';
    src: url('../../fonts/FuturaClassicBold.eot');
    src: url('../../fonts/FuturaClassicBold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaClassicBold.woff2') format('woff2'),
         url('../../fonts/FuturaClassicBold.woff') format('woff'),
         url('../../fonts/FuturaClassicBold.ttf') format('truetype'),
         url('../../fonts/FuturaClassicBold.svg#FuturaClassicBold') format('svg');
    font-weight: 700;
    font-style: normal;
}

// Futura Paratype Light (300)
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-Light.eot');
    src: url('../../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-Light.woff2') format('woff2'),
         url('../../fonts/FuturaPT-Light.woff') format('woff'),
         url('../../fonts/FuturaPT-Light.ttf') format('truetype'),
         url('../../fonts/FuturaPT-Light.svg#FuturaClassicLight') format('svg');
    font-weight: 300;
    font-style: normal;
}

// Futura Paratype Light (300) Italic
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-LightObl.eot');
    src: url('../../fonts/FuturaPT-LightObl.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-LightObl.woff2') format('woff2'),
         url('../../fonts/FuturaPT-LightObl.woff') format('woff'),
         url('../../fonts/FuturaPT-LightObl.ttf') format('truetype'),
         url('../../fonts/FuturaPT-LightObl.svg#FuturaPT-LightObl') format('svg');
    font-weight: 300;
    font-style: italic;
}

// Futura Paratype (400) Normal
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-Book.eot');
    src: url('../../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-Book.woff2') format('woff2'),
         url('../../fonts/FuturaPT-Book.woff') format('woff'),
         url('../../fonts/FuturaPT-Book.ttf') format('truetype'),
         url('../../fonts/FuturaPT-Book.svg#wf') format('svg');
    font-weight: 400;
    font-style: normal;
}

// Futura Paratype Normal (400) Italic
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-BookObl.eot');
    src: url('../../fonts/FuturaPT-BookObl.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-BookObl.woff2') format('woff2'),
         url('../../fonts/FuturaPT-BookObl.woff') format('woff'),
         url('../../fonts/FuturaPT-BookObl.ttf') format('truetype'),
         url('../../fonts/FuturaPT-BookObl.svg#FuturaPT-BookObl') format('svg');
    font-weight: 400;
    font-style: italic;
}

// Futura Paratype (500) Medium
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-Medium.eot');
    src: url('../../fonts/FuturaPT-Medium.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-Medium.woff2') format('woff2'),
         url('../../fonts/FuturaPT-Medium.woff') format('woff'),
         url('../../fonts/FuturaPT-Medium.ttf') format('truetype'),
         url('../../fonts/FuturaPT-Medium.svg#wf') format('svg');
    font-weight: 500;
    font-style: normal;
}

// Futura Paratype Bold (700)
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-Bold.eot');
    src: url('../../fonts/FuturaPT-Bold.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-Bold.woff2') format('woff2'),
         url('../../fonts/FuturaPT-Bold.woff') format('woff'),
         url('../../fonts/FuturaPT-Bold.ttf') format('truetype'),
         url('../../fonts/FuturaPT-Bold.svg#FuturaPT-Bold') format('svg');
    font-weight: 700;
    font-style: normal;
}

// Futura Paratype Bold (700) Italic
@font-face {
    font-family: 'Futura';
    src: url('../../fonts/FuturaPT-BoldObl.eot');
    src: url('../../fonts/FuturaPT-BoldObl.eot?#iefix') format('embedded-opentype'),
         url('../../fonts/FuturaPT-BoldObl.woff2') format('woff2'),
         url('../../fonts/FuturaPT-BoldObl.woff') format('woff'),
         url('../../fonts/FuturaPT-BoldObl.ttf') format('truetype'),
         url('../../fonts/FuturaPT-BoldObl.svg#FuturaPT-BoldObl') format('svg');
    font-weight: 700;
    font-style: italic;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500; 
}

h3 {
    font-size: 2.2rem;
}

//internet explorer underline fix; first add underline before we can remove it
.e-url {
  &:after {
    text-decoration: underline;
  }
}

.ext-url {

  &:after {
    text-decoration: none !important;
    content:"\E89E";
    display:inline-block;
    font-family: 'Material Icons';
    font-size: 1.133rem;
    margin:0 3px 0 3px;
    line-height: 1.133rem;
  }
}

a{
  color: $link-color;
}