#notification-style {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    right: 0;
    min-width: 400px;
    overflow-x: hidden;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);
    background: $color-primary-white;
    .notificationReadAll {
        padding: 15px;
        text-align: center;
        a {
            color: $site-header-notification-text-color;
        }
    }

    p {
        color: $site-header-notification-text-color;
    }

    li {
        width: 100%;
        margin: 0;
        line-height: 100%;
        overflow: hidden;

        &.notification {
            padding: 15px;
            text-align: left;

            &.unread {
                border-left: 5px solid $notification-left-border-unread;
                background-color: $notification-element-background-unread;
            }

            .notificationLink {
                width: 100%;
                height: 100%;
                float: left;
                padding: 0;

                &.ext-url:after {
                    display: none;
                }

                &:hover {
                    background-color: initial;
                }
            }

            div {
                float: left;
                width: 70%;
            }

            p {
                float: left;
                line-height: 100%;
                width: calc(100% - 145px);
                font-size: 16px;
                margin: 15px 0 0 15px;
            }

            .notificationLinkContainer {
                float: right;
                height: 35px;
                font-size: 18px;
                font-weight: bold;
                width: 19px;
                margin: 15px 20px 0 15px;
                color: $color-primary-white;
                padding: 0;

                img {
                    width: 19px;
                }
            }

            img {
                &.profile-pic {
                    float: left;
                    height: 75px;
                    width: 75px;
                    border-radius: 45px;
                }
            }
        }
    }

    hr {
        width: 100%;
        border: 1px solid $notification-divider;
        margin: 0;
    }

    > p {
        text-align: center;
    }
}
#notifications-page {
    border-left: 1px solid $site-header-basket-icon-color;
    .notification-icon {
        line-height: 2rem;
    }
    ul.notification-container {
        margin: 0;
        @extend #notification-style;
    }
}

.app_view {
    #notification-style {
        min-width: auto;
        max-width: 100%;
    }

    .notification-container {
        padding-bottom: 60px;
    }

    .notificationReadAll {
        position: fixed;
        bottom: 0;
        border-top: 2px solid $notification-divider;
        background: $color-primary-white
    }
}
