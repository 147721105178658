.mrc-shadow {
    pointer-events: none;
    position: absolute;
    height: 100px;
    left: 0; right: 0; bottom: 0;
    z-index: 999; 

    background:$timeline-gradient-background;

}
.readMoreLess {
    text-decoration: underline;
    cursor: pointer;
}
