.site-navigation {
    .brand-logo {

        width:256px;
        height:80%;
        line-height:80%;
        background-size:22px 50px;
        top:10%;
        background-size: contain;
        background-repeat: no-repeat;  
        background-position: center;
        @media #{$large-and-up} {
            width:120px;
            height:92px;
            line-height:92px;
            margin-top:22px;
            margin-left:40px;                
        }
        
    }
    .basket-button-collapse{
        .basketCounter{
            width: 25px;
            height: 25px;
            position: absolute;
            top: calc(50% - 12px);
            left: 34px;
            background-color: red;
            border-radius: 50%;
            line-height: 25px;
            text-align: center;
            font-weight: 700;
            color: #fff;
        }
    }
}

.shrink {
    .site-navigation {
        .brand-logo{
            @media #{$large-and-up} {
                width: 40px;
                line-height:80%;
                height: 80%;      
                margin-top:0px;           
            }        
        }

        .main-menu-button-collapse i{
            @media #{$large-and-up} {
                line-height: 75px;
                margin-top: 0px;
            }
        }
    }
}

.sticky-header-container {
    position: relative;
    z-index: 99999998;
    .sticky-header {
        position: fixed;
        width: 100%;
        display: block;
        background: $sticky-header-message-bg-color;
        .sticky-header-message {
            padding: 10px;
            text-align: center;
            color: $sticky-header-message-color;            
            font-weight: 700;
            a{
                color: $sticky-header-message-color;
                text-decoration: underline;
            }
        }
    }
}

.sticky-header-container + .site-main{
    padding-top: 42px;
}