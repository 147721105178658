// -----------------------------------------------------
// Main site navigation
// -----------------------------------------------------

        nav .mobile_menu_active .menu-basket-container, nav .side-nav .menu-basket-container {
            i.material-icons{
                width:30px;
                float:left;
                display:inline-block;
                margin-right:5px;
            }

            span{
                height: 25px;
                width: 25px;
                border-radius: 50%;
                font-size: .8rem;
                line-height: 24px;
                font-weight: 700;
                text-align: center;
                display: inline-block;
                background: red;
                color: #fff;
            }
        }
$menu-margin-left: 10px;

.site-navigation {
    background-color: $site-header-bg-color;
    color: $site-header-text-color;
    font-weight: 300;
    .brand-logo {
        color: $site-header-logo-text-color;
        font-weight: bold;
        transition: all .3s ease-in-out;

    }

    ul.right {

        @media #{$large-and-up} {
            margin-right:30px;

        }

        a {
            color: $site-header-link-color;
            font-size:0.95rem;
            .menuIcon {
                font-size: 2rem;
                text-align: center;
                line-height: 24px;
            }

        }
        .active{
            a{
                font-weight: 700;
            }
        }

        li.menu-item > a {
            text-align: center;
        }



        li.lang-switch {

            padding: 0 15px;
            text-align: left;
            svg.icon-globe,
            img.icon-globe {
                width: 20px;
                height: 20px;
                margin-bottom: -1px;
                fill: currentColor;
            }

            a {
                display: inline;
                padding: 0;
                color: $site-header-link-color;

                &.active {
                    font-weight: 400;
                }

            }

        }


        li .menu-basket-container{
            background:transparent;
            display:inline;
            margin: 7px 0;
            padding: 3px 0 6px;
            position:relative;
            i{
                font-size:1.667rem;
                color: $site-header-basket-icon-color;
                display: inline-block;
                line-height: 7px;
                height: 50px;
                background: 0 0;
                margin: 0 0 7px;
                padding: 0 0 6px;
                position: relative;

            }

            span{
                top: -7px;
                right: -22.5px;
                height:25px;
                width:25px;
                border-radius: 50%;
                font-size:0.8rem;
                line-height:24px;
                font-weight:bold;
                text-align:center;
                display:inline-block;
                position:absolute;
                background: $site-header-basket-count-background;
                color: $site-header-basket-count-color;
            }
        }

        li .notification-set-read {
            display: inline-block;
            line-height: 7px;
            height: 50px;
            background:transparent;
            margin: 0 0 7px 0;
            padding: 0 12px 6px $menu-margin-left;
            position:relative;
            i{
                font-size:2rem;
                line-height: 30px;
                display: inline;
                color: $site-header-basket-icon-color;

            }

            span{
                top:-2px;
                right:-7px;
                height:20px;
                width:20px;
                border-radius: 50%;
                font-size:0.8rem;
                line-height:19px;
                font-weight:bold;
                text-align:center;
                display:inline-block;
                position:absolute;
                background: $site-header-basket-count-background;
                color: $site-header-basket-count-color;
                &.notificationCounter {
                    top: 17px;
                    right: 0;
                    height: 25px;
                    width: 25px;
                    line-height: 24px;
                }
            }
        }

        li.login-logout {

            padding: 0 10px;

            a {
                display: inline;
                margin: 7px 0;
                padding: 3px 12px 6px;
                border: 1px solid $site-header-button-border-color;
            }

        }
        li{
            text-align: center;
            line-height: 3rem;
            margin-top: 3rem;
            &.hasChildren{
                li{
                    margin-top: 0;
                }
                &:after{
                    font-size:1.2rem;
                }
            }
        }
    }

    ul#mobile-menu, ul#basket-menu {
        z-index: 99999999;
        bottom: 0;
        height: 100% !important;
        .icon-globe {
            max-width: 20px;
        }
        li > a {
            padding: 0 22px;
            font-size: 1.25rem;
            .menuIcon {
                display: none;
            }
        }

        li.header {
            position: relative;
            background-color: $site-mobile-nav-header-bg-color;

            a {
                height: 64px;
                color: $site-mobile-nav-header-text-color;
                font-size: 2em;
                line-height: 60px;
            }

            i {
                position: absolute;
                top: 19px;
                right: 12px;
                width: 27px;
                height: 27px;
                transition: transform .8s ease-in-out;
                font-size: 27px;
                line-height: normal;
                cursor: pointer;
                &:hover {
                    transform: rotate(225deg);
                }
            }

        }

        li.lang-switch {

            padding: 0 22px;
            color: $site-mobile-nav-header-lang-switch;

            svg.icon-globe {
                width: 15px;
                height: 13px;
                margin-bottom: -1px;
                fill: #000;
                color: $site-mobile-nav-header-lang-globe;
            }

            a {
                display: inline;
                padding: 0;

                &.active {
                    font-weight: bold;
                }

            }
        }

        li ul{
            padding-left: 15px;
        }

        li.notification {
            overflow-y: auto;
            img {
                margin: 10px;
                margin-left: 0;

                float: left;
                width: 35%;
            }

            p {
                float: right;
                line-height: 1rem;
                width: calc(65% - 10px);
            }

            a {
                float: right;
            }
        }

        hr {
            clear: both;
            margin-left: -15px;
        }

        li.menu-notification {
            height: calc(100%);
        }

        // li.login-logout {

        //     padding: 0 15px;

        //     a {
        //         display: inline;
        //         padding: 3px 12px 6px;
        //         border: 2px solid $site-mobile-nav-header-button-bortfrt;
        //     }

        // }

    }
    #mobile-notification-menu {
        display: none;
        .notification-container {
            margin-top: 60px;
        }
        li.menu-notification {
            border-left: none;
            .notificationLink:hover {
                background-color: initial;
            }
        }
    }
    .button-collapse i.material-icons, .button-collapse .menuIcon{
        font-size: 47px;
        margin-top:3px;
    }
    .notification-button-collapse{
        .notificationCounter{
            width: 25px;
            height: 25px;
            position: absolute;
            top: calc(50% - 12px);
            left: 34px;
            background-color: red;
            border-radius: 50%;
            line-height: 25px;
            text-align: center;
            font-weight: 700;
            color: #fff;
        }
    }
}
.navbar-fixed {
    position: relative;
    height: 56px;
    /*z-index: 99999998;*/
    z-index: 99999999;
}
.navbar-fixed nav {
    position: fixed;
}
nav{
    ul.right, ul.side-nav{
        li.menu-basket{
            .col{
                padding:0;
            }
            position:relative;
            ul.menu-basket-content{
                right:0;
                line-height: 1rem;
                padding: 20px;
                li{
                    &.basket-item{
                        list-style: none;
                        padding: 10px 0 5px 75px;
                        min-height: 80px;
                        position: relative;
                        overflow: hidden;
                        vertical-align: top;
                        line-height: 1.33;
                        &.no-photo{
                            padding-left: 0px;
                        }
                        >a{
                            font-size: 1.3em;
                            display: block;
                            margin-bottom: 5px;
                            padding: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 1.3;
                            img{
                                top: 10px;
                                position: absolute;
                                left: 0;
                                width: 60px;
                                height: 60px;
                                margin-bottom: 5px;
                                object-fit: cover;
                                object-position: 50% 50%;
                                transition: opacity 1s;
                                opacity: 1;
                                max-width: 100%;
                                height: auto;
                                display: inline-block;
                                vertical-align: middle;
                            }
                        }
                        .quantity{
                            display: block;
                            margin-top: 3px;
                            font-size: .85em;
                            opacity: 0.6;
                            color: $color-primary-dark;
                        }
                        .amount{
                            white-space: nowrap;
                            color: $color-primary-dark;
                            font-weight: bold;
                            font-size: 1em;
                        }

                    }
                    &.checkout {
                        margin-top: 10px;
                        a{
                            width: calc(100% - 30px);
                            color: $color-primary-white;
                            background-color: $color-primary-tint-5;
                        }
                    }
                }
            }
        }
    }
    ul#basket-menu{
        li.menu-basket{
            a.menu-basket-link{
                display:none;
            }
            ul{
                li.basket-item{
                    padding: 0 22px 0 99px;
                    &.no-photo{
                        padding-left: 22px;
                    }
                    img{
                        left: 22px;
                    }
                    a{
                        padding:0px;
                        line-height: 48px;
                        &:hover{
                            background-color: transparent;
                        }
                    }

                }
            }
        }
    }
    ul.right {
      li.menu-basket {
        ul.menu-basket-content {
          position: absolute;

          li {
            &.basket-item {
              > a {
                .image-container {
                  display: block;
                }
              }
            }

            .row {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    li.menu-notification{
        border-left: 1px solid $site-header-basket-icon-color;
        .notification-icon {
            line-height: 2rem;
        }
        ul.notification-container{
            @extend #notification-style;
            position:absolute;
            display:none;
            width: 30%;
            right: 50px;
            max-height: 73vh;
            height: auto;
            overflow:hidden;

            .notification-subcontainer{
                position:relative;
                max-height:73vh;
                ul{
                    opacity: 1;
                    overflow-y: auto;
                    max-height: 73vh;
                    display: none;
                    padding-bottom:60px;
                }
            }

            .notificationReadAll{
                position:absolute;
                bottom:0;
                background:#fff;
            }
        }
    }
}
#basket-collapse,
#notification-collapse,
#mobile-collapse {
    color: $site-header-basket-icon-color;
}

@media #{$medium-and-up} {
    nav ul.right li {
        margin-left: 2rem;

        &:hover{
            background:transparent;
        }

        a{

            &:hover {
                background:transparent;
            }
        }

        &.active  {
            background-color:transparent;
        }
        &.menu-basket{
            &:after{
                content: "";
            }
        }
        &.hasChildren, &.menu-basket{
            position: relative;

            a{
                padding-right:2rem;
            }
            ul{
                display:none;

                background: $site-submenu-bg-color;
                width: 100%;
                min-width: 240px;
                position: absolute;
                box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.3);

                li{
                    margin-left: 0;
                    display: block;
                    width:100%;
                    a{
                        color: $site-submenu-color;
                        line-height: 3rem;
                        padding: 0 15px;
                        font-weight: 400!important;

                        .menuIcon{
                            display:none;
                        }
                    }

                    &:first-child{
                        margin-top:10px;
                    }
                    &:last-child{
                        margin-bottom:10px;
                    }

                    &.active, &:hover {
                        background: $site-submenu-bg-color;
                        a{
                            color:$site-submenu-active-color;
                        }
                    }


                }

            }
        }
    }
}

@media #{$large-and-up} {


    nav.nav-extended .nav-wrapper {
        min-height: 158px;
        transition: all .3s ease-in-out;
    }

    nav, nav .nav-wrapper i, nav a.button-collapse, nav a.button-collapse i {
        height: 158px;
        line-height: 158px;
        transition: all .3s ease-in-out;
    }

    .navbar-fixed {
        height: 158px;
        transition: all .3s ease-in-out;
    }
    main{
        transition: all .3s ease-in-out;
    }

    .shrink {
        .navbar-fixed {
            height:74px;
        }
        .site-navigation{
            height:74px;
            line-height: 74px;
            ul.right {
                li {
                    margin-top: 10px;
                }
            }
            ul.notification-container {
                li {
                    margin-top: 0;
                }
            }
        }

        main{
            margin-top:74px;
        }
    }
}

#sidenav-overlay {
    z-index: 99999997;
}

@media #{$extra-large-and-up} {
    nav ul.right{
        li{
            margin-left: 3rem;
            &.hasChildren{
                a{
                    padding: 0 15px;
                }
            }
            &.menu-item {
                margin-left: $menu-margin-left;
            }
        }
    }
}
@media only screen and (min-width: 995px) and (max-width: 1052px) {
    nav.site-navigation {
        a.button-collapse {
            display: block;
            height: 50px;
            line-height: 50px;
            i {
                line-height: 75px;
                height: 50px;
            }
        }
    }
}
@media only screen and (min-width: 995px) and (max-width: 1140px) {
    nav ul.right li {
        margin-left: 1.4rem;
    }
}
@media only screen and (min-width: 600px) and (max-width: 1300px) {
    #mobile-collapse,
    #basket-collapse,
    #notification-collapse {
        display: none;
    }
    nav ul.right li {
        margin-left: 7px;
    }
    nav .brand-logo {
        left: 30px;
    }
    nav ul.right li.hasChildren a, nav ul.right li.menu-basket a {
        padding-right: 0;
    }
    nav ul a {
        padding: 0 5px;
    }
    .site-navigation ul.right li .notification-set-read {
        padding: 0 12px 6px 5px;

    }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
    .site-navigation ul.right li {
        margin-top: 6px;
    }
}
@media only screen and (min-width: 995px) and (max-width: 1300px){
    .shrink {
        .site-navigation ul.right li {
            margin-top: 10px !important;
        }
    }
}
@media only screen and (max-width: 600px){
    .site-navigation .brand-logo {
        display: none;
    }
}
