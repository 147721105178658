// -----------------------------------------------------
// Your main project specific sass files
// Author: Jurgen Oldenburg
// -----------------------------------------------------

// // Import generic includes
// @import 'includes/materialize';            // Include the main Materialize styles.
// @import 'includes/variables';              // Include middle layer variables (mainly colors)
// @import 'includes/mixins';                 // Include custom mixins
// @import 'includes/utils';                  // Include utility classes like background colors
// @import 'includes/typography';             // Include typographic styles for displaying custom webfonts
// @import 'includes/svg-icons';              // Include SVG icon styles
// @import 'includes/triangles';              // Include the triagle mixin system

// // Import the theme file routing all the variables to colors
// //@import 'themes/default';
// @import 'themes/pontsteiger';

@import 'includes/font-awesome';            // Include the main Materialize styles.
@import 'includes/materialize-social';      // Include the main Materialize styles.

// Get all component specific styles
@import "components/notification";         // Notifications
@import 'components/navbar';               // Main site menu / navbar
@import 'components/sellingpoints';        // Unique sellingpoints carousel
@import 'components/header';               // Main site header
@import 'components/footer';               // Main site footer
@import 'components/forms';                // Main form styling
@import 'components/pickadate';            // Pickadate datepicker styling
@import 'components/buttons';              // Button styling
@import 'components/cards';                // Cards styling
@import 'components/events';
@import 'components/page-sections';
@import "components/detail_page";
@import "components/toast";
@import "components/modal";
@import "components/pagination";           // Page pagination
@import "components/collapsibles";
@import "components/ccbar";
@import "components/select2";
@import "components/timeline";         // Timeline
@import "components/readMoreLess";         // more less
@import "components/myRepairs";         // more less

//Get all pages specific styles
@import "pages/profile";
@import "pages/_service-page";

// Get page specific styling
@import "components/infobox-form";                // Custom login-page specific styles

// Get al specific section styling
@import 'sections/panorama';               // Panorama (page header) section styling
@import 'sections/apps';                   // Apps section styling
@import 'sections/infobox';                // Infobox section styling
@import 'sections/news';                   // News section styling
@import 'sections/people';                 // People (aanwezig) section styling
@import 'sections/repair';                 // Repair requests section styling
@import 'sections/servicegrid';            // Services Icons section styling
@import 'sections/testimonials-carousel';  // Testimonials carousel section styling
@import 'sections/cms-block';              // CMS blocks
@import 'sections/floorservice';           // Floorservice page

// select2
@import 'includes/select2';
@import 'includes/lightbox2';

// PhoneNumber Thing
@import "includes/intlTelInput";

@import '../../../../../node_modules/owl.carousel/dist/assets/owl.carousel.css';



// Overide media query margin
@media only screen and (min-width: 993px){
    .container {
        width: 90%;
    }
}

body {
    background: $body-background-color;
    color: $body-font-color;
    font-family: $body-font;
    .app-button{
        display:none;
    }
}

::selection {
    background: $body-selection-background;
    color: $body-selection-color;
}

.js-block-link {
    cursor: pointer;
}



h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font;
    font-weight: 500;
}

.modal-overlay{
    z-index: 1002 !important;
}

.page-section .section-bg .section-image-overlay {
    background-color: black;
    opacity: .5;
}

body.app_view{
    header#nav{
        display:none !important;
    }

    .app-button{
        display:block;
    }

    .products-grid .product-image {
        padding-top: 60%;
    }

    .waves-effect {
        z-index: unset;
    }

    .well .sticky-bottom,
    .row .sticky-bottom {
        padding: 5px .75rem;
        // iPhone x setting...
        @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3) {
            padding-bottom: env(safe-area-inset-bottom);
        }
        background-color: $bottom-navigation-background-color;
        position: fixed;
        display: inline-block;
        bottom: 0;
        left: 0;
        font-size: 20px;
        text-align: left;
        width: 100%;
        z-index:99999;
        .right{
            margin-right:5px;

            &.btn-arrow{
                padding-right: 30px;
                min-width: 0;

            }
        }
        .btn{
            background:none;
            margin-left: 5px;
            color: $bottom-navigation-color;
            height: 2.56rem;
            i{
                color: $bottom-navigation-color;
                border:none;

            }
        }
    }

    .repair{
        background-color: $color-primary-white;
    }
    nav#notifications-page {
        height: 0;
        line-height: 0;
        li.menu-notification {
            ul.notification-container {
                display: block;
                width: 100%;
                height: 100%;
                position: relative;
                right: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                .notificationLink:hover {
                    background-color: initial;
                }
            }
        }
    }

    .cc_banner-wrapper{
        display:none;
    }

    .triangle-1{
        display:none!important;
    }

    .profile-heading {
        display:none;
    }
}

.responsive.product_overview img{
    width: 100px;
    height: 100px;
    object-fit: cover;
}

@media all and (max-width: 600px){
    .service-products .container {
        .center-align{
            text-align:left;
        }

        .collapsible-header{
            margin-bottom:5px;
            .valign-wrapper {
                background-color: $service-collapsible-header;
                width: 100%;

                .material-icons{
                    display: inline-block;
                    color: #fff;
                    position: absolute;
                    right: 1em;
                    top: 0.6em;
                    transform: rotate(-45deg);
                }

                h5 {
                    width: 100%;
                    text-align: center;
                    color: #fff;
                }

                i {
                    display: none;
                }
            }

            &.active{
                .valign-wrapper {
                    .material-icons{
                        display: inline-block;
                        color: #fff;
                        position: absolute;
                        right: 1em;
                        top: 0.4em;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .collapsible-body{
            padding:0;
            .btn{
                width:100%;
                max-width:100%;
            }
            .amount-box label{
                width:100%;
            }
            input[type="text"]{
                width:80%;
                max-width:80%;
                text-align:center;
            }
            .material-icons{
                width:10%;
            }

            .text-align-right-mobile{
                text-align:right;
            }
        }
    }


    .app-button.with-text a{
        padding-left: 38px;
        i {
            left: 0;
            right: auto;
        }

        span {
            line-height: 2.5rem;
        }
    }
}

// @import 'themes/pontsteiger-styles';

@media all and (max-width: 400px){
    div.login-form div.input-field label.indent {
        font-size: 0.9rem;
    }
}
