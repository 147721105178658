.picker__table{
    width:100%!important;
    td{
        padding:0!important;
    }
}

.picker__date-display {
    background-color: $pickadate-left-bg-color;
}

.picker__nav--next:hover,
.picker__nav--prev:hover {
    background: transparant;
}

.picker--focused .picker__day--selected,
.picker__day--selected,
.picker__day--selected:hover {
    transform: scale(.9);
    border-radius: 50%;
    background-color: $pickadate-day-bg-color;
    color: $pickadate-day-text-color;
    max-width:35px;
    max-height:40px;
    display:block;
    line-height:5px;
    margin:0 auto;
    @media #{$medium-and-up} {
        max-width:40px;
    }
}

.picker__day.picker__day--today {
    color: $pickadate-day-today-text-color;
    font-weight: bold;
}

.picker__day--infocus {
    padding: 1rem 0;
}

.picker__clear,
.picker__close,
.picker__today {
    color: $pickadate-picker-text-color;
    font-weight: bold;
}

button.picker__clear:focus,
button.picker__close:focus,
button.picker__today:focus {
    background-color: $pickadate-picker-fucos-bg-color;
}

.picker__close{
    background-color: $pickadate-picker-fucos-bg-color;
    color:$pickadate-picker-close-color;
    &:focus{
        opacity:0.8;
    }
}

.picker--opened .picker__holder{
  //top:157px;
  top: 0;
  .shrink &{
      top:54px;
      @media #{$medium-and-up} {
        top:64px;
      }
     @media #{$large-and-up} {
         top:74px;
     }
  }
}

tr{
    display:table-row!important;
}