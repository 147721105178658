// -------------------------------------
// Utility Mixins
// -------------------------------------

@mixin filter-blur($blur: 2px) {
    filter: blur($blur);
}


// Widths: 300, 700
@mixin font-variable($weight: normal) {
    font-family: 'Variable';
    font-weight: $weight;
    font-feature-settings: "kern", "liga" 0, "calt" 0;
}


// Widths: 300, 400, 700
@mixin font-futura-classic($weight: normal) {
    font-family: 'Futura-Classic';
    font-weight: $weight;
    font-feature-settings: "kern", "liga" 0, "calt" 0;
}


// Widths: 300, 400, 700
@mixin font-futura($weight: normal) {
    font-family: 'Futura';
    font-weight: $weight;
    font-feature-settings: "kern", "liga" 0, "calt" 0;
}


// Widths: 300, 400, 700
@mixin font-futura($weight: normal) {
    font-family: 'Futura';
    font-weight: $weight;
    font-feature-settings: "kern", "liga" 0, "calt" 0;
}
