// -------------------------------------
//   Repair request section styling
// -------------------------------------

.page-section.repair {
    .section-header{
        span{
            width:100%;
            text-align:center;
            font-size:1.5rem;
            display:block;

            @media #{$large-and-up} {
                width:50%;
                margin:0 auto 2rem auto;
            }
        }
    }

    .triangle-1 {
        @include triangle-south-west($repair-trianble-1-bg-color, 100px);
        position: absolute;
        z-index: 1;
        bottom: -72px;
        left: -15px;
    }

    .step-nav{
        list-style:none;
        padding:0;
        margin:0;
        height:90px;
        position:relative;
        display:none;

        @media #{$large-and-up} {
            display:block;
        }

        .line{
            height:2px;
            background:$color-primary-dark-3;
            position:relative;
            left:0;
            right:0;
            top:34px;
            margin-left:auto;
            margin-right:auto;
            z-index:1;
            display:none;
            @media #{$large-and-up} {
                display:block;
            }
        }

        li{
            list-style-type:none;
            text-align:center;
            position:relative;
            z-index:2;
            overflow:hidden;

            div{
                width:50px;
                height:50px;
                background: $repair-request-step-block-background-color;
                display:inline-block;
                font-size: 2.813rem;
                text-align:center;
                line-height:50px;
                border:2px solid $color-primary-dark-3;
                color:$color-primary-dark-3;
                position:relative;
                @media #{$large-and-up} {
                    width:70px;
                    height:70px;
                    line-height:70px;
                }
            }

            span{
                display:block;
                width:100%;
                text-align:center;
                bottom:0px;
                font-size:1.25rem;
                margin-top:1.25rem;
            }

            &:nth-child(2){
                div{
                    &:before{
                        content:'';
                        border-left:200px solid $color-primary-white;
                        height:70px;
                        width:1px;
                        background:$repair-request-step-line-background-color;
                        display:block;
                        position:absolute;
                        left:-202px;
                        top:-2px;
                    }
                }
            }

            &:last-child{
                div{
                    &:after{
                        content:'';
                        border-left:200px solid $color-primary-white;
                        height:70px;
                        width:1px;
                        background:$repair-request-step-line-background-color;
                        display:block;
                        position:absolute;
                        right:-202px;
                        top:-2px;
                    }
                }
            }

            &.active{
                div{
                    background:$color-primary-white;
                    border:2px solid $repair-request-step-block-border-color;
                    color: $repair-request-step-block-color;
                }
                span{
                    font-weight:500;
                }
            }
        }
    }

    .categories{
        li{
            cursor:pointer;
            list-style-type:none;
            text-align:left;
            position:relative;
        }
    }


    .steps{
        margin-top:40px;
        .step{
            display:none;
            font-size:1.875rem;
            .input-field{
                margin-top:0;
            }
            #app_user_repair_request_category{
                visibility:hidden;
                margin-top:0px;
                padding-top:0px;
                height:1px;
            }
            ul{
                padding:0;
                margin:0;
                list-style-type:none;
                li{
                    margin:0;
                    padding:0;
                    list-style-type:none;
                    font-size:1.5rem;

                    div{
                        label{
                            font-size:1.375rem;
                        }
                    }
                }
            }

            h3{
                width:100%;
                text-align:center;
            }

            h4{
                font-size:1.25rem;
                margin-top:1rem;
            }

            .stepheader{
                margin-top:0rem;
                @media #{$large-and-up} {
                    display:none;
                }
            }

            .btn:last-child{
                margin:0 auto;
                display:block;
                width:200px;
            }

            textarea{
                width:100%;
                margin:0 auto 1rem auto;
                display:block;
                height:5rem;
                background-color:$color-primary-white;
            }

            .file-box{
                width:300px;
                margin:0 auto;
                text-align:center;
                margin-bottom:2rem;

                b{
                    display:block;
                    width:100%;
                    text-align:center;
                    margin-bottom:1rem;
                }
            }

            [data-parent] {
                display:none;
            }

            .edit{
                display:none;

                .underline {
                    padding:10px 0 5px 0;
                    border-bottom:1px solid $repair-request-edit-border-color;
                }

                span{
                    display:block;
                    cursor:pointer;
                    float:right;
                    border-left:1px solid $repair-request-edit-border-color;
                    padding-left:10px;
                    font-size:1.313rem;
                    font-weight:400;
                }

                .selected-cat{

                    width:120px;
                    font-weight:400;
                    display:none;

                    @media #{$medium-and-up} {
                        display:inline-block;
                    }

                    &:after{
                        content:'>';
                        display:inline-block;
                        margin-right:10px;
                        float:right;
                    }

                    .stepnumber{
                        display:inline;
                    }


                }
            }

            &:nth-child(2){
                display:block;
            }

            &.last-step{
                text-align: center;
                .send_request_text{

                    font-weight:400;
                    @media #{$large-and-up} {
                        margin-top:2rem;
                    }
                    .reload_repair{
                        font-size:1.313rem;
                        text-decoration:underline;
                    }
                }
            }

            .btn-left{
                @media #{$large-and-up} {
                    float:left;
                }
            }

            .btn-right{
                @media #{$large-and-up} {
                    float:right;
                }
            }
        }

        .stephistory{
            padding:0;
            margin:0;
            li{
                border-bottom:1px solid $repair-request-step-border-color;
                padding:0;
                margin:0;
                list-style-type:none;
                span{
                    display:block;
                    float:right;
                    cursor:pointer;
                }
            }
        }
    }

    .repair-videos{
        width: 100%;

        .hide{
            //display: none;
            opacity: 0;
        }
        .preview-container {
            position: relative;
            margin: 1rem 0 0 0;
        }

        .play-preview {
            margin: auto;
            position: absolute;
            top: 32%;
            right: 0;
            bottom: 0;
            left: 41%;
        }

        .play-button{
            color: $repair-play-button;
            padding: 0.25rem 1.0rem;
            border-radius: 8px;
            background: none;

            &:hover{
                padding: 0.25rem 1.0rem;
                border-radius: 8px;
                cursor: pointer;
                background: $repair-play-button-bg;
            }
        }

        .play-button-url{
            font-size:2.5rem;
            &:after{
                display:none;
            }
        }

        .modal {
            z-index: 100000 !important;
            display: none;
            overflow: hidden;
        }

        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }

        .modal-trigger {
            border: none;
            background: none;
            font-size: 2.5rem;
            color: $color-primary-white;
            opacity: 0.9;
        }

        .modal-iframe {
            max-width: 100%;
        }

        .modal-close:hover {
            border-radius: 6px;
            background: $repair-modal-close-button;
            color: $color-primary-white;
            transition: 0.5s;
        }

        .modal-close-top {
            position: absolute;
            top: 2%;
            right: 2%;
            border: none;
            background: none;
            padding: 0 0.5rem 0 0.5rem;
            transition: 0.5s;

            &:hover{
                border-radius: 6px;
                padding: 0 0.5rem 0 0.5rem;
                background: $repair-modal-close-button;
            }
        }
    }

    .video-description-container {
        max-width: 100% !important;
        padding: 0 0 0 0;
        margin-top: 0.25rem;

        .video-logo{
            width: 42px;
            position: relative;
            top: 0.25rem;
            margin-right: 0.75rem;
        }

        .video-title-container {
            padding: 0;
        }
    }

    label{
        font-size: 1rem !important;
        font-weight: 600;
    }

    .tooltip {
        position: relative;
        display:inline-block;

        i {
            position: relative;
            bottom: 0.35rem;
            font-size: smaller;

            &:hover {
                cursor: help;
            }
        }
    }

    .tooltip-text {
        visibility: hidden;
        background: $repair-tooltip-bg-color;
        color: $color-primary-white;
        font-weight: 500;
        padding: 0.75rem 0.75rem;
        border-radius: 4px;
        box-shadow: 1px 1px 6px 0 rgba(0,0,0,0.75);
        text-align: center;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;
        white-space: nowrap;
    }

    .tooltip .tooltip-text::after {
        display: none;
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #555 transparent transparent transparent;
    }

    .tooltip:hover .tooltip-text {
        visibility: visible;
        opacity: 1;
    }

    @media only screen and (max-width: 580px) {
        .modal {
            width: 95%;
        }

        .video-logo {
            top: 0 !important;
            text-align: center;
        }
    }

    @media only screen and (max-width: 520px) {
        .modal {
            width: 95%;
        }

        .video-logo {
            top: 0 !important;
            text-align: center;
        }
    }

    @media only screen and (max-width: 420px) {
        .modal-content{
            padding: 1rem;
        }

        .video-title-container{
            padding-top: 0.75rem !important;
        }

        .video-logo {
            top: 0 !important;
        }

        .video-title-container {
            font-size: 0.75rem !important;

            .tooltip i {
                font-size: 1rem;
            }
        }
    }

    @media only screen and (max-width: 390px) {

        .video-title-container {
            padding-top: 0 !important;
        }
    }

    @media only screen and (max-width: 320px) {
        .video-logo {
            min-width: 1.5rem;
            width: 2rem;
            position: relative;
            top: 0 !important;
            margin-right: 0.75rem;
        }
    }

    @media only screen and (max-width: 256px) {
        .video-logo {
          display:none;
        }

        .video-logo-container {
            display:none;
        }

        .video-title-container{
            margin-left: 1.25rem;
        }
    }
}

@media all and (max-width: 780px) {
    .page-section.repair {
        .steps .step h3 {
            width: 90%;
            text-align: center;
            margin: 1.46rem auto 1.168rem auto;
        }

        div.row ul.step-nav {
            display: block;

            .line {
                display: block;
            }

            li {
                padding: 0.2rem;
                span {
                    font-size: 0.9rem;
                }
            }

            li:nth-child(2) div:before,
            li:nth-child(5) div:after {
                display: none;
            }
        }
    }
}
@media all and  (min-width: 601px) {
    .page-section.repair {
        .video-description-container {
            height: 75px;
        }
    }
}
@media all and  (min-width: 640px) {
    .page-section.repair {
        .video-description-container {
            height: 55px;
        }
    }
}
