#flash-modals{
    /*z-index: 99999999;*/
    z-index: 100000000;
    position: absolute;
    font-size:1.33rem;
}

section.detail-page .modal{
    margin-top: 50px;

    a{
        text-decoration: none;
        color:#000;
    }
}

#cancelRegistration{
    margin-top: 150px;
    z-index: 999999!important;
}

.service-modal.modal{
    margin-top: 150px;
    z-index: 999999999!important;
    .modelLink {
        text-decoration: underline;
        font-weight: 600;
    }
}

@media screen and (max-width:1400px){
    .modal{
        width:70%;
    }
}
@media screen and (max-width:1200px){
    .modal{
        width:75%;
    }
}
