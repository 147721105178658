.icon {
    font-size: 10px;
}

.icon:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("/assets/dist/svg/sprite.svg");
    background-repeat: no-repeat;
    background-size: 25.09em 145.5em;
}

.no-svg .icon:before {
    background-image: url("../images/sprite.png");
}



  .icon.angle-left:before {
      background-position: 0em 0em;
      width: 2.3em;
      height: 4.3em;
  }




  .icon.angle-right:before {
      background-position: 0em -4.3em;
      width: 2.3em;
      height: 4.3em;
  }




  .icon.arrow-down:before {
      background-position: 0em -8.6em;
      width: 3.3200000000000003em;
      height: 4.82em;
  }




  .icon.arrow-left:before {
      background-position: 0em -13.5em;
      width: 4.82em;
      height: 3.3200000000000003em;
  }




  .icon.arrow-right:before {
      background-position: 0em -16.9em;
      width: 4.82em;
      height: 3.3200000000000003em;
  }




  .icon.arrow-up:before {
      background-position: 0em -20.3em;
      width: 3.3200000000000003em;
      height: 4.82em;
  }




  .icon.icon-buggy:before {
      background-position: 0em -25.2em;
      width: 7.57em;
      height: 7.57em;
  }




  .icon.icon-cargo:before {
      background-position: 0em -32.8em;
      width: 11.6em;
      height: 7.540000000000001em;
  }




  .icon.icon-dog:before {
      background-position: 0em -40.4em;
      width: 8.24em;
      height: 6.9em;
  }




  .icon.icon-exchange:before {
      background-position: 0em -47.3em;
      width: 7.92em;
      height: 7.94em;
  }




  .icon.icon-flowers:before {
      background-position: 0em -55.3em;
      width: 6.8em;
      height: 8.16em;
  }




  .icon.icon-globe:before {
      background-position: 0em -63.5em;
      width: 9em;
      height: 9em;
  }




  .icon.icon-hanger:before {
      background-position: 0em -72.5em;
      width: 10.47em;
      height: 7.56em;
  }




  .icon.icon-info:before {
      background-position: 0em -80.1em;
      width: 8.48em;
      height: 11.059999999999999em;
  }




  .icon.icon-iron:before {
      background-position: 0em -91.2em;
      width: 9.61em;
      height: 5.470000000000001em;
  }




  .icon.icon-sewing:before {
      background-position: 0em -96.7em;
      width: 8.22em;
      height: 7.540000000000001em;
  }




  .icon.icon-shoe:before {
      background-position: 0em -104.3em;
      width: 10.459999999999999em;
      height: 4.2em;
  }




  .icon.icon-taxi:before {
      background-position: 0em -108.5em;
      width: 8.92em;
      height: 7.01em;
  }




  .icon.icon-tools:before {
      background-position: 0em -115.6em;
      width: 7.529999999999999em;
      height: 7.529999999999999em;
  }




  .icon.icon-vacuumcleaner:before {
      background-position: 0em -123.2em;
      width: 8.25em;
      height: 8.25em;
  }




  .icon.info:before {
      background-position: 0em -131.5em;
      width: 1.67em;
      height: 4.05em;
  }




  .icon.logo:before {
      background-position: 0em -135.6em;
      width: 25.09em;
      height: 5.07em;
  }




  .icon.plus:before {
      background-position: 0em -140.7em;
      width: 4.74em;
      height: 4.74em;
  }
