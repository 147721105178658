// -------------------------------------
// Cards styling
// -------------------------------------


.card {
    margin-bottom: 3rem;
    background-color: $card-bg-color;
    .card-image {
        height: 150px;
        overflow: hidden;
    }

    .card-date {
        background-color: $card-date-bg-color;
        color: $card-date-text-color;
        font-size: 1.5rem;
        font-weight: 400;
        .day {
            display: inline-block;
            min-width: 50px;
            margin-right: 10px;
            padding: 8px 11px;
            background-color: $card-date-day-bg-color;
            color: $card-date-day-text-color;
            text-align: center;
        }

    }

    .card-content{

        h3 {
            margin: 0 0 1rem;
            font-size: 2rem;
            font-weight: 400;
            line-height: 110%;
        }
        p{
            width:100%;
            font-weight: 300;
        }

        .light{
            color: $card-text-light-color;
            display:block;
            line-height: 1rem;
        }

    }

    .card-action {
        padding: 0 24px 24px;
        border-top: 0;

        .btn{
            margin-right: 5px;
            margin-bottom: 5px;
        }
    }

    &.card-news {
        box-shadow: none;
        background-color: transparent;

        .card-image {
            height: 220px;
            border-top: solid 10px $card-img-border-color;

            .page-section.dark & {
                //set border to light when in dark section
                border-top: solid 10px $card-img-border-color-light;
            }
            .page-section.light & {
                //set border to light when in light section
                border-top: solid 10px $card-img-border-color-light;
            }

            .page-section.blank & {
                //set border to dark when in blank section
                border-top: solid 10px $card-img-border-color-dark;
            }
        }
        .pinnedImage {
            color: $card-overlay-icon-color;
            position: absolute;
            margin: 0;
            font-size: 2rem;
            width: auto;
            top: 5px;
            right: 5px;
            text-shadow: 0px 0px 5px $card-overlay-icon-shadow-color;
        }
        .card-content {
            padding: 24px 0;
        }

        .card-action {
            padding: 0;

        }
    }

    &.card-floorservice {
        box-shadow: none;
        background-color: transparent;

        label{
            cursor:pointer;
            height:100%;
            display:block;
            padding:0;


        }

        input:checked + label {
            background:$card-floorservice-selected-item-background-color;
        }

        [type=radio]+label:after, [type=radio]+label:before{
            display:none;
        }

        .card-image {
            border-bottom: solid 10px $card-img-border-color;
            text-align:center;

            img{
                width:auto;
                display:inline-block;

            }

            svg{
                width:200px;
                height:100px;
                padding:20px 0px 20px 0px;
                fill: $card-floorservice-nav-label-icon-color;

                &.breedte{
                    width:150px;
                }
            }

            .specs{
                font-size:1.625rem;
                color:$card-text-color;
            }
        }

        .card-content {
            padding: 6px 0 24px 0;

            p{
                max-width:100%;
                color:$card-text-color;
                text-align:center;
            }

            .desc{
                font-size:1rem;
            }
        }

        .card-action {
            padding: 0;

        }
    }
}

.card-side {
    .image {
        height: 100px;
        width: 100px;
        overflow: hidden;
        float:left;
        margin:0 15px 15px 0;

    }
    .title {
        font-size: 1rem;
        font-weight:bold;
    }

    .content{
        .date {
            font-size: 0.8rem;
            font-weight:bold;
        }
    }

    .read-more{
        font-size: 1rem;
        font-weight:bold;
        text-decoration:underline;
        color:$card-link-color;
    }
}

.product-card{
    h2{
        font-size:2rem;
    }

    ul{
        list-style-type:disc;
        font-size:1.3rem;
        li{
            list-style-type:disc;
            margin: 0px 0px 0px 20px;
        }
    }
    .card-action{
        margin-bottom:50px;

        .button-container{
            position: relative;
            display: inline-block;

            .header{
                position:absolute;
                bottom: 3rem;
                min-width:11rem;
                text-align:center;
                font-size:1rem;
                color:$color-primary-dark;

                @media #{$small-and-down} {
                    width:100%;
                }
            }

            @media #{$small-and-down} {
                display:block;
            }
            a{
                display:inline-block;
                margin-top:40px;
                position:relative;

                @media #{$small-and-down} {
                    display:block;
                }

                &.btn-extra-margin{

                    @media #{$small-and-down} {
                    margin-top:80px;
                    }
                }

            }
        }
    }
}
