$timelineMarginMobile: 15px;
$timelineMarginDesktop: 30px;
.timelineContainer {
  .section-header {
    display: none;
  }

  &.repair-detail-page {
    .section-header {
      display: block;
    }
  }
  .profileContainer {
    .userInformation {
      border-top: 1px solid $color-primary-gray-1;
      padding: $timelineMarginDesktop 0px $timelineMarginMobile 0px;
      display: inline-block;
      width: 100%;

      div {
        width: 80%;
        margin: 0 auto;
      }

      p {
        font-size: 1rem;
        margin: 0;

        &.fullName {
          max-width: 100%;
        }
      }

      b {
        display: block;
      }

      .imageSection {
        width: 50px;
        height: 50px;
        float: left;
        margin-right: 15px;
        overflow: hidden;

        div {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat !important;
          background-position: center !important;
          background-size: cover !important;
          background: $color-primary-dark;
          border-radius: 50%;
          color: $color-primary-white;
        }
      }
    }

    .profileMenu {
      border-top: 1px solid $color-primary-gray-1;
      margin-top: $timelineMarginMobile;
      padding: $timelineMarginDesktop 0px 0px 0px;
      font-size: 1rem;

      ul {
        margin: 0 auto;
        width: 80%;
        padding: 0;
        list-style: none;
        display: block;
        li {
          width: 100%;
          list-style: none;
          a {
            font-size: 1rem;
            height: 30px;
            line-height: 30px;
            display: block;
            color: $timeline-url-color;
            .menuIcon {
              float: left;
              font-size: 1.25rem;
              line-height: 25px;
              margin-left: 17px;
              margin-right: 10px;
              @media #{$extra-large-and-up} {
                margin-right: 32px;
              }
            }
            &:hover {
              text-decoration: underline;
            }
          }

          ul {
            width: 100%;
            display: block;
            li {
              width: 100%;
              list-style: none;
              margin: 0;
              padding: 0;
              height: 30px;
              line-height: 30px;

              a {
                font-size: 1rem;
                .menuText {
                  float: left;
                }
                &:hover {
                  span {
                    text-decoration: underline;
                  }
                }
              }

              &.lang-switch {
                a {
                  width: calc(100% - 45px);
                  @media #{$extra-large-and-up} {
                    width: calc(100% - 68px);
                  }
                  float: right;
                  &:hover {
                    text-decoration: underline;
                  }
                }
                .icon {
                  float: left;
                  width: 19px;
                  height: 25px;
                  margin-left: 17px;
                  display: block;
                  line-height: 25px;
                  margin-right: 9px;
                  @media #{$extra-large-and-up} {
                    margin-right: 32px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .hidden {
    display: none;
  }

  .sticky {
    position: -webkit-sticky; /* Safari */
    position: sticky;
  }

  .formContainer {
    form[name="timeline_message"] {
      border: 1px solid $color-primary-gray-1;
      background: $timeline-message-background;
      padding: $timelineMarginDesktop;
      overflow: hidden;

      input,
      .input-field {
        margin: 0;
        border-bottom: 0px;
      }

      textarea {
        border: 1px solid $color-primary-gray-1;
        min-height: 30px;
        font-size: 1.2rem;
        padding: 0.9rem 1.7rem;
        @media #{$large-and-up} {
          min-height: 200px;
        }

        &.minHeight {
          min-height: 300px;
        }
      }

      .mediaUploadSection {
        width: 50%;
        float: left;
        .input-field {
          text-indent: 30px;

          .btn {
            background: transparent;
            color: $color-primary-dark;
            &:before {
              display: block;
              position: absolute;
              left: -30px;
              top: 0px;
              font-family: "font-icons" !important;
              content: "\44";
            }
          }

          .file-field {
            .file-path-wrapper {
              left: -30px;
              top: 30px;
              padding: 0;
              width: 100%;
              input {
                font-size: 1rem;
                left: 0px;
                position: absolute;
                height: 30px;
                margin-bottom: 15px;
                border-bottom: 0;
                box-shadow: 1px 1px 1px transparent;
              }
            }
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;
            .error {
              margin: 0;
              padding: 0;
              list-style: none;
              font-size: 1rem;
            }
          }
        }
      }

      .saveButton {
        float: right;
        position: relative;

        .btn {
          float: right;
        }

        .loadIcon {
          width: 100%;
          height: 100%;
          background: url("/images/loader.gif") no-repeat center center $color-primary-tint-1;
          position: absolute;
          z-index: 10;
          display: none;
        }
      }

      .replyButtons {
        display: none;
      }

      &.replyForm {
        padding: 0 5px $timelineMarginMobile $timelineMarginMobile !important;
        @media #{$large-and-up} {
          padding: 0 $timelineMarginMobile $timelineMarginMobile $timelineMarginDesktop !important;
        }
        .saveButton,
        .mediaUploadSection {
          display: none;
        }

        .imageSection {
          width: 40px;
          height: 40px;
          float: left;
          margin-right: 20px;
          overflow: hidden;

          div {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            background: $color-primary-tint-5;
            border-radius: 50%;
          }
        }

        .replyButtons {
          display: block;
          border: 0;
          float: right;
          position: relative;

          @media #{$medium-and-up} {
            border-right: 1px solid $color-primary-gray-1;
            border-top: 1px solid $color-primary-gray-1;
            border-bottom: 1px solid $color-primary-gray-1;
            width: 130px;
            float: left;
          }

          height: 40px;
          line-height: 40px;
          .menuIcon {
            font-size: 1.33rem;
            height: 40px;
            width: 40px;
          }
          label {
            width: 80px;
            text-align: center;
            float: left;
            line-height: 40px;
            font-size: 1.33rem;
            color: $color-primary-gray-5;
            cursor: pointer;
          }

          .sendButton,
          .menuIcon {
            color: $color-primary-gray-1;
            &.active {
              color: $color-primary-tint-5;
            }
            &.bold {
              font-weight: 600;
              line-height: 38px;
            }
          }

          .sendButton{
            color: $timeline-send-button-color;
          }

          .loadIcon {
            width: 80px;
            height: 38px;
            background: url("/images/loader.gif") no-repeat center center $color-primary-tint-1;
            position: absolute;
            right: 10px;
            display: none;
          }

          &.active {
            @media #{$large-and-up} {
              border-right: 1px solid $color-primary-tint-5;
              border-top: 1px solid $color-primary-tint-5;
              border-bottom: 1px solid $color-primary-tint-5;
            }
          }
        }

        .input-field {
          float: left;
          padding-right: 10px;
          width: calc(100% - 65px);
          border: 1px solid $color-primary-gray-1;

          @media #{$medium-and-down} {
            &.active {
              border: 1px solid $color-primary-tint-5;
            }
          }

          @media #{$medium-and-up} {
            border-right: 0;
            border-left: 1px solid $color-primary-gray-1;
            border-top: 1px solid $color-primary-gray-1;
            border-bottom: 1px solid $color-primary-gray-1;
            width: calc(100% - 200px);
          }

          height: 40px;
          overflow: hidden;
          input#timeline_message_message {
            border: 0 !important;
            box-shadow: 1px 1px 1px $color-primary-gray-1;
          }

          &.active {
            border-left: 1px solid $color-primary-tint-5;
            border-top: 1px solid $color-primary-tint-5;
            border-bottom: 1px solid $color-primary-tint-5;
          }
        }

        .mediaUploadSection {
          width: 100%;
          padding-left: 60px;
          height: 80px;
          .input-field {
            width: 100%;
            border: 0;
            overflow: visible;

            ul {
              margin: 0;
              padding: 0;
              list-style: none;
              .error {
                margin: 0;
                padding: 0;
                list-style: none;
                font-size: 1rem;
              }
            }
          }
        }
      }
    }

    .push-notification {
      display: none;
      #pushMessageLabel {
        position: relative;
      }
    }
  }

  #timelineSegment {
    #modalConfirmMessage{
      blockquote {
        border-left: 5px solid $timeline-preview-quote;
      }
      @media #{$large-and-up} {
        top: 18% !important;
      }
      .modal-footer {
        height: 62px;
        .btn-edit {
          background: $form-infobox-bg-color;
          color: $form-infobox-text-color;
        }
      }
    }

    #noMoreMessages{
      display:none;
    }

    #messageBoard {
      form[name="timeline_message"] {
        box-shadow: none;
        border: none;
      }

      .message {
        background: $timeline-message-background;
        margin-bottom: 30px;
        border: 1px solid $color-primary-gray-1;
        position: relative;

        .pinnedImage {
          position: absolute;
          right: 15px;
          top: 15px;
          font-size: 1.33rem;
          z-index: 3;
          color: $color-primary-gray-5;
        }

        .typeImage {
          position: absolute;
          right: 15px;
          top: 40px;
          font-size: 1.5rem;
          z-index: 3;

          @media #{$large-and-up} {
            right: 60px;
            top: 30px;
            font-size: 2rem;
          }
        }

        .messageHeader {
          overflow: hidden;

          padding: $timelineMarginMobile;
          @media #{$large-and-up} {
            padding: $timelineMarginMobile $timelineMarginDesktop;
          }

          border-bottom: 1px solid $color-primary-gray-1;
          min-height: 40px;

          .imageSection {
            width: 60px;
            height: 60px;
            display: block;
            float: left;
            margin-right: 15px;
            overflow: hidden;

            div {
              width: 100%;
              height: 100%;
              background-repeat: no-repeat !important;
              background-position: center !important;
              background-size: cover !important;
              background: $color-primary-dark;
              border-radius: 50%;
            }
          }

          p {
            font-size: 1rem;
            max-width: 100%;
            margin: 8px 0 0 0;
            padding-left: 60px;

            .userName {
              font-weight: 600;
              color: $color-primary-dark;
            }
            .address {
              color: $color-primary-gray-3;
            }

            .time {
              float:right;
              color: $timeline-reply-username-color;
            }
            .message-data {
              display: block;
            }
          }
        }

        .happyContainer {
          padding: $timelineMarginMobile;
          @media #{$large-and-up} {
            padding: $timelineMarginMobile $timelineMarginDesktop $timelineMarginMobile $timelineMarginDesktop;
          }
          overflow: hidden;
          .btn.happy {
            background: transparent;
            color: $color-primary-tint-6;
            font-size: 1rem;
            height: 30px;
            line-height: 25px;
            padding: 0;
            font-weight: 300;
            float: left;
            width: 80px;
            text-align: left;

            &:before {
              font-family: "font-icons" !important;
              text-transform: none;
              line-height: 1;
              content: "\56";
              color: $color-primary-tint-6;
              font-size: 1rem;
              background: transparent;
              margin: 0 0.5rem 0 0;
              mask: none;
              font-weight: 300;
              display: inline-block;
            }

            &:hover {
              text-decoration: underline;
              &:before {
                text-decoration: none;
              }
            }

            &.happied {
              font-weight: 600;
              text-decoration: none;
              line-height: 22px;
              &:before {
                text-decoration: none;
                font-weight: 600;
              }
              &:hover {
                text-decoration: underline;
              }
            }
          }

          .happies {
            height: 30px;
            line-height: 25px;
            display: block;
            margin-left: $timelineMarginMobile;
            float: left;
            color: $color-primary-gray-5;
            .totalHappies {
              cursor: pointer;
              text-decoration: underline;
              display: inline-block;
              &:hover {
                .happyPeople {
                  display: block;
                }
              }
            }
            .happyPeople {
              position: absolute;
              padding: 10px;
              display: none;
              z-index: 3;
              box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
                0 2px 4px -1px rgba(0, 0, 0, 0.3);
              max-height: 50vh;
              overflow-y: auto;
              background-color: $color-primary-white;
              color: $color-primary-dark;
            }
          }
        }

        .responses {
          .messageHeader {

            >div.content {
              margin-left: 60px;
            }
            .imageSection {
              width: 40px;
              height: 40px;

              div {
                background: $color-primary-tint-5;
              }
            }

            .info {
              display: flex;
            }
            .userName, .time, .message-data {
              display: block;
            }
            .userName {
              color: $timeline-reply-username-color;
              flex-grow: 1;
            }

            .time {
              width: 185px;
              text-align: right;
            }

            .message-data {
              width:100%;
              display: inline-block;
            }

            .images {
              padding:0;
            }

            &:last-child {
              padding-bottom: $timelineMarginMobile !important;
              @media #{$large-and-up} {
                padding-bottom: $timelineMarginDesktop !important;
              }
            }

            p {
              margin: 0;
              padding:0;
            }
          }

          @media screen and (max-width:650px){
              .info {
                flex-direction: column;
                .time {
                  text-align: left;
                }
              }
          }
        }

        .additionalResponses {
          padding: 0px 30px $timelineMarginMobile 75px;
          @media #{$large-and-up} {
            padding: 0px 30px $timelineMarginMobile 90px;
          }
          margin: 0;
          display:block;
          cursor: pointer;
        }

        .responses {
          .messageHeader {
            border-bottom: 0px;
            padding-bottom: 0px !important;
          }

          .images {
            padding: 0px $timelineMarginMobile 0px 60px;
            @media #{$large-and-up} {
              padding: 0px $timelineMarginDesktop 0px 60px;
            }

            padding: 0px 30px 0px 60px;
            width: 100%;
            .timelineImage {
              height: 45px;
              min-width: 45px;

              @media #{$large-and-up} {
                height: 90px;
                min-width: 90px;
              }

              margin: 0 5px 5px 0px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 50% 50%;
              border: 1px solid $color-primary-gray-1;

              &.active {
                border: 0;
              }
            }
          }
        }

        .messageBody {
          font-size: 1rem;
          margin: 0;
          padding: $timelineMarginMobile;
          @media #{$large-and-up} {
            padding: $timelineMarginDesktop;
          }
          h2 {
            font-size: 1.334rem;
            font-weight: bold;
            margin-top: 0;
          }
          p {
            margin: 0 0 1em 0;
            max-width: 100%;
          }

          .alert {
            color: $color-alert;
          }

          table {
            width: 100% !important;
          }

          strong,
          b {
            font-weight: 600;
            color: $color-primary-dark;
          }

          * {
            font-size: 1rem;
          }

                        img{
                            width:100%;
                            max-width: 100%;
                            height:auto !important;
                        }
                        a {
                            color: $timeline-link-color;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }

        .additionalResponses {
          font-size: 1rem;
        }

        .images {
          display: flex;
          flex-flow: row wrap;
          width: 100%;
          .image {
            flex: auto;
            height: 250px;
            min-width: 200px;
            &.smallerHeight {
              height: 150px;
              .timelineImage {
                height: 150px;
              }
            }
            .timelineImage {
              background-repeat: no-repeat;
              background-size: cover;
              background-position: 50% 50%;
              height: 250px;
              &.active {
                background-size: contain;
              }
            }
            &.breakThree {
              @media #{$large-and-up} {
                min-width: 300px;
              }
            }
          }

          .replyImage {
            .timelineImage {
              background-size: cover;
              &.active {
                background-size: contain;
              }
            }
          }

          .material-placeholder {
            width: 100%;
          }
          .singleImage {
            min-width: 100%;
            max-width: 100%;
            &.active {
              max-width: 100vw;
              min-width: 100%;
            }
          }
        }

        .formContainer {
          form[name="timeline_message"] {
            padding: 15px 30px 30px 30px;

            #timeline_message_message {
              border: 1px solid $color-primary-gray-1;
              margin-bottom: 15px;
              padding: 0 10px;
            }
          }
        }

        .card {
          margin: 0;
          box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);

          .card-action {
            margin-top: 24px;
          }
          .btn {
            background: transparent;
            color: $color-primary-tint-6;

            &.btn-check {
              padding-left: 10px;
              font-size: 1rem;
              font-weight: 600;
              height: 1.8rem;
              line-height: 1rem;

              &.checked {
                .check {
                  &:before {
                    display: none;
                    font-weight: 300;
                    font-size: 1.8rem;
                  }

                  &:after {
                    font-family: "font-icons" !important;
                    text-transform: none;
                    line-height: 1;
                    content: "\55";
                    color: $color-primary-tint-6;
                    font-size: 1.97rem;
                    position: absolute;
                    background: transparent;
                    margin: 0;
                    mask: none;
                    font-weight: 300;
                  }
                }
              }

              .check {
                &:before {
                  font-family: "font-icons" !important;
                  text-transform: none;
                  line-height: 1;
                  content: "\5a";
                  display: block;
                  color: $color-primary-tint-6;
                  font-size: 1.8rem;
                  position: absolute;
                  font-weight: 300;
                }

                &:after {
                  background: transparent;
                  font-weight: 300;
                }

                box-shadow: 0 0 0 0 transparent inset;
                background: transparent;
                left: 0;
                border-left: 0;
                border-right: 5px solid transparent;
              }
            }
          }
        }
      }
    }
  }

  #contactSection {
    .contactPart {
      .serviceManagerInformation {
        border-top: 1px solid $color-primary-gray-1;
        padding: 30px 0px 0px 0px;
        font-size: 1rem;

        .imageSection {
          width: 50px;
          height: 50px;
          float: left;
          margin-right: 15px;
          overflow: hidden;

          div {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat !important;
            background-position: center !important;
            background-size: cover !important;
            background: $color-primary-dark;
            border-radius: 50%;
          }
        }

        .precensePerson {
          padding-bottom: 30px;
        }

        .precenseTime {
          border-top: 1px solid $color-primary-gray-1;
          padding: 30px 0px 0px 0px;
          font-size: 1rem;
          line-height: 2rem;
          
          i {
            font-size: 1.25rem;
          }

          div {
            width: 85%;
            margin: 0 auto;

            a {
              color: $timeline-url-color;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .info-box-wrapper {
        padding-top: 15px;
        margin-top: 30px;
        border-top: 1px solid $color-primary-gray-1;
      }
      .infoBox {
       
        padding: 15px 0px 0px 0px;
        font-size: 1rem;
        color: $color-primary-gray-5;
        display: inline;

        a {
          color: $color-primary-gray-5;
          text-decoration: underline;
        }
      }
    }
  }

  
  .app-preview {
    display: none;
    text-align: center;

    &.alert {
      text-align: left;
    }
    .preview {
      background-size: contain;
      background-repeat: no-repeat;
      min-height: 140px;
      margin-bottom: 25px;
      width: 286px;
      display:inline-block;
      @media screen and ( max-width:500px ) {
        width:100%;
      }
      p {
        word-wrap: break-word;
        font-size: 12px;
        text-align: left;
        background: $color-primary-white;
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 5px;
        -webkit-box-shadow: 0 1px 2px 0 $timeline-alternate-text, 0 1px 3px 1px $timeline-alternate-text;
        box-shadow: 0 1px 2px 0 $timeline-alternate-text, 0 1px 3px 1px $timeline-alternate-text;
        min-height: 85px;
        margin: 0 0 15px 0;
      }
     
    }
    #ios {
      background-image: url(/images/iphone.png);
      padding: 45px 23px 0 23px;
      box-sizing: border-box;
      p {
        border-radius: 5px;
      }
    }

    #android {
      background-image: url("/images/android.png");
      padding: 52px 23px 0 23px;
      box-sizing: border-box;
    }
  }

  .modal-content {

    .app-preview {
      margin-top: 25px;
      .preview {
        margin: 0 25px;
        @media screen and ( max-width:500px ) {
          margin: 0;
        }
      }
    } 
  }

  @media screen and ( max-width:500px ) {
    .btn {
      padding: 0 1.35rem;
    }
  }
}

